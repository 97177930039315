import { Input, InputRef, Space } from 'antd';
import { Ref, useEffect, useRef, useState } from 'react';

export default function NPWPInput({ value, onChange, disabled, form }) {
  const [npwpValue, setNpwpValue] = useState({
    part1: '',
    part2: '',
    part3: '',
    part4: '',
    part5: '',
    part6: '',
  });  

  const npwpRefs1: Ref<InputRef> = useRef(null);
  const npwpRefs2: Ref<InputRef> = useRef(null);
  const npwpRefs3: Ref<InputRef> = useRef(null);
  const npwpRefs4: Ref<InputRef> = useRef(null);
  const npwpRefs5: Ref<InputRef> = useRef(null);
  const npwpRefs6: Ref<InputRef> = useRef(null);

  useEffect(() => {
    setNpwpValue({
      part1: value?.split('.')?.[0] || '',
      part2: value?.split('.')?.[1] || '',
      part3: value?.split('.')?.[2] || '',
      part4: value?.split('.')?.[3]?.split('-')?.[0] || '',
      part5: value?.split('.')?.[3]?.split('-')?.[1] || '',
      part6: value?.split('.')?.[4] || '',
    });
  }, [value]);

  function handleInputChange(e, part, nextRef, prevRef) {
    const val = e.target.value;

    if (/^\d*$/.test(val)) {
      const copyValue = JSON.parse(JSON.stringify(npwpValue));
      copyValue[part] = val;
      const formattedValue = `${copyValue.part1}.${copyValue.part2}.${copyValue.part3}.${copyValue.part4}-${copyValue.part5}.${copyValue.part6}`;
      onChange(formattedValue);

      if (val.length === e.target.maxLength && nextRef) {
        nextRef.current.focus();
      }
    }

    if (val === '' && e.nativeEvent.inputType === 'deleteContentBackward' && prevRef) {
      prevRef.current.focus();
    }
  }

  return (
    <Space.Compact  block>
      <Input
        ref={npwpRefs1}
        value={npwpValue.part1}
        onChange={(e) => handleInputChange(e, 'part1', npwpRefs2, null)}
        maxLength={2}
        minLength={2}
        style={{ width: '12%', textAlign: 'center' }}
        placeholder="00"
        disabled={disabled}
      />
        <span style={{ width: "4%", textAlign: 'center' }}>.</span>
      <Input
        ref={npwpRefs2}
        value={npwpValue.part2}
        onChange={(e) => handleInputChange(e, 'part2', npwpRefs3, npwpRefs1)}
        maxLength={3}
        minLength={3}
        style={{ width: '15%', textAlign: 'center' }}
        placeholder="000"
        disabled={disabled}
      />
        <span style={{ width: "4%", textAlign: 'center' }}>.</span>
      <Input
        ref={npwpRefs3}
        value={npwpValue.part3}
        onChange={(e) => handleInputChange(e, 'part3', npwpRefs4, npwpRefs2)}
        maxLength={3}
        minLength={3}
        style={{ width: '15%', textAlign: 'center' }}
        placeholder="000"
        disabled={disabled}
      />
      <span style={{ width: "4%", textAlign: 'center' }}>.</span>
      <Input
        ref={npwpRefs4}
        value={npwpValue.part4}
        onChange={(e) => handleInputChange(e, 'part4', npwpRefs5, npwpRefs3)}
        maxLength={1}
        minLength={1}
        style={{ width: '8%', textAlign: 'center' }}
        placeholder="0"
        disabled={disabled}
      />
        <span style={{ width: "4%", textAlign: 'center' }}>-</span>
      <Input
        ref={npwpRefs5}
        value={npwpValue.part5}
        onChange={(e) => handleInputChange(e, 'part5', npwpRefs6, npwpRefs4)}
        maxLength={3}
        minLength={3}
        style={{ width: '15%', textAlign: 'center' }}
        placeholder="000"
        disabled={disabled}
      />
        <span style={{ width: "4%", textAlign: 'center' }}>.</span>
      <Input
        ref={npwpRefs6}
        value={npwpValue.part6}
        onChange={(e) => handleInputChange(e, 'part6', null, npwpRefs5)}
        maxLength={3}
        minLength={3}
        style={{ width: '15%', textAlign: 'center' }}
        placeholder="000"
        disabled={disabled}
      />
    </Space.Compact>
  );
}
