import moment from 'moment';

export const getDuration = (value, procedure, form) => {
  const timeDiff = form.getFieldValue(procedure);
  form.setFieldsValue({ duration: '' });
  if (!value) return;
  if (!timeDiff) return;

  let value1 = new Date(value);
  let value2 = new Date(timeDiff);

  let duration = null;
  switch (procedure) {
    default:
      return;
    case 'start_time':
      if (timeDiff.isBefore(value)) {
        duration =
          Math.floor(value1.getTime() / 1000 / 60) - Math.floor(value2.getTime() / 1000 / 60);
      }
      break;
    case 'end_time':
      if (value.isBefore(timeDiff)) {
        duration =
          Math.floor(value2.getTime() / 1000 / 60) - Math.floor(value1.getTime() / 1000 / 60);
      }
      break;
  }

  let mm = Math.floor(duration) % 60;
  let hh = Math.floor(duration / 60);

  const formattedTime = `${hh.toString().padStart(2, '0')}:${mm.toString().padStart(2, '0')}`;
  form.setFieldsValue({ duration: formattedTime });
};

export const validateStartTime = (value, form) => {
  if (!value) return Promise.reject('Waktu Mulai Harus Diisi');
  const timeDiff = form.getFieldValue('end_time');
  if (moment().isBefore(value, 'day')) {
    return Promise.reject(new Error('Mulai lembur tidak dapat melebihi tanggal hari ini'));
  }

  if (!timeDiff) return;
  let value1 = new Date(value);
  let value2 = new Date(timeDiff);
  if (Math.floor(value1.getTime() / 1000 / 60) >= Math.floor(value2.getTime() / 1000 / 60)) {
    return Promise.reject(new Error('Waktu Mulai Harus Lebih Kecil Dari Waktu Berakhir'));
  }

  return Promise.resolve();
};
