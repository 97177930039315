import { Input, Select, Space } from 'antd';
import { memo, useMemo, useState } from 'react';
import JoinWithUsUsecase from '../../JoinWithUs.usecase';

const useCase = new JoinWithUsUsecase();

function SelectLocationComponent({ value, onChange }) {
  useMemo(() => {
    getProvince();
  }, []);

  const onChangeLocation = (e, field) => {
    switch (field) {
      case 'province':
        getCities(e);
        break;
      case 'city':
        getDistrict(e);
        break;
      case 'district':
        getSubdistrict(e);
        break;
      case 'subdistrict':
        const code = value?.listSubdistrict?.find((item) => item.value === e).postal_code;
        onChange({ ...value, [field]: e, postal_code: code });
        break;
    }
  };

  async function getProvince() {
    const getProvince = await useCase.getProvince({
      country_id: [101],
    });
    if (getProvince?.data?.content && getProvince?.data?.content.length > 0) {
      const content = getProvince?.data?.content;
      const options = content?.map((opt) => {
        return { key: opt.id, label: opt.name, value: opt.id };
      });
      onChange({ 
        ...value, 
        listProvince: options,
        listCity: [],
        listDistrict: [],
        listSubdistrict: [],
      });
    }
  }

  async function getCities(id: number) {
    onChange({ 
      ...value, 
      province: id, 
      city: null, 
      district: null, 
      subdistrict: null, 
      postal_code: null,
    });
    const cities: any = await useCase.getCities({ prov_id: [id] });
    if (!!cities) {
      const content = cities?.data?.content;
      const options = content?.map((opt) => {
        return { key: opt.ID, label: opt.Name, value: opt.ID };
      });
      onChange({ 
        ...value, 
        province: id, 
        city: null, 
        district: null, 
        subdistrict: null, 
        postal_code: null, 
        listCity: options,
        listDistrict: [],
        listSubdistrict: [],
      });
    }
  }

  async function getDistrict(id: number) {
    onChange({ 
      ...value, 
      city: id, 
      district: null, 
      subdistrict: null, 
      postal_code: null,
    });
    const district: any = await useCase.getDistrict({ kab_id: [id] });
    if (!!district) {
      const content = district?.data?.content;
      const options = content?.map((opt) => {
        return { key: opt.ID, label: opt.Name, value: opt.ID };
      });
      onChange({ 
        ...value, 
        city: id, 
        district: null, 
        subdistrict: null, 
        postal_code: null,
        listDistrict: options,
        listSubdistrict: [],
      });
    }
  }

  async function getSubdistrict(id: number) {
    onChange({ 
      ...value, 
      district: id, 
      subdistrict: null, 
      postal_code: null,
    });
    const subDistrict: any = await useCase.getSubdistrict({ kec_id: [id] });
    if (!!subDistrict) {
      const content = subDistrict?.data?.content;
      const options = content?.map((opt) => {
        return { key: opt.ID, label: opt.Name, value: opt.ID, postal_code: opt.KodePos };
      });
      onChange({ 
        ...value, 
        district: id, 
        subdistrict: null, 
        postal_code: null,
        listSubdistrict: options,
      });
    }
  }

  return (
    <Space.Compact style={{ width: '100%' }} size="large">
      <Select
        showSearch
        loading={!value?.listProvince?.length}
        options={value?.listProvince}
        value={value?.province}
        placeholder="Pilih Provinsi"
        onChange={(e) => onChangeLocation(e, 'province')}
        filterOption={(input: any, option: any) =>
          option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
      <Select
        showSearch
        loading={!value?.listCity?.length}
        options={value?.listCity}
        value={value?.city}
        disabled={!value?.province}
        placeholder="Pilih Kab/Kota"
        onChange={(e) => onChangeLocation(e, 'city')}
        filterOption={(input: any, option: any) =>
          option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
      <Select
        showSearch
        loading={!value?.listDistrict?.length}
        options={value?.listDistrict}
        value={value?.district}
        disabled={!value?.city}
        placeholder="Pilih Kecamatan"
        onChange={(e) => onChangeLocation(e, 'district')}
        filterOption={(input: any, option: any) =>
          option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
      <Select
        showSearch
        loading={!value?.listSubdistrict?.length}
        options={value?.listSubdistrict}
        value={value?.subdistrict}
        disabled={!value?.district}
        placeholder="Pilih Kelurahan"
        onChange={(e) => onChangeLocation(e, 'subdistrict')}
        filterOption={(input: any, option: any) =>
          option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
      <Input disabled placeholder="Kode Pos" value={value?.postal_code} />
    </Space.Compact>
  );
}

export default memo(SelectLocationComponent);
