export const UserListConstant = {
    TABLE_HEADER: [
      {
        title: 'Nama Pengguna',
        key: 'user_name',
        dataIndex: 'user_name',
        width: 150,
      },
      {
        title: 'Nama Karyawan',
        key: 'employee_name',
        dataIndex: 'employee_name',
        width: 150,
      },
      {
        title: 'Jabatan',
        key: 'job_name',
        dataIndex: 'job_name',
        width: 180,
      },
      {
        title: 'Tipe User',
        key: 'user_type',
        dataIndex: 'user_type',
        width: 180,
      },
      {
        title: 'Hak Akses',
        key: 'hak_akses',
        dataIndex: 'hak_akses',
        width: 150,
      },
      {
        title: 'Zona Waktu',
        key: 'timezone',
        dataIndex: 'timezone',
        width: 150,
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: 150,
      },
      {
        title: 'Dikunci',
        key: 'is_locked',
        dataIndex: 'is_locked',
        width: 120,
      },
      { title: 'Dibuat Oleh', key: 'create_name', dataIndex: 'create_name', width: 150 },
      { title: 'Dibuat Pada', key: 'create_date', dataIndex: 'create_date', width: 150, sorter: true, },
      { title: 'Diubah Oleh', key: 'write_name', dataIndex: 'write_name', width: 150 },
      { title: 'Diubah Pada', key: 'write_date', dataIndex: 'write_date', width: 150, sorter: true, },
    ],
    SEARCH_COLUMN_KEY: [],
    SEARCH_COLUMN_COMPONENT: [
      { key: 'key', componentType: "text" },
      { key: 'user_name', componentType: "text" },
      { key: 'employee_name', componentType: "text" },
      { key: 'job_name', componentType: "text" },
      { key: 'user_type', componentType: "select", options: [
        { label: 'Semua', value: '' },
        { label: 'ESS', value: 'ess' },
        { label: 'System Admin', value: 'system' },
      ]},
      { key: 'is_locked', componentType: "select", options: [
        { label: 'Semua', value: '' },
        { label: 'Ya', value: 'aktif' },
        { label: 'Tidak', value: 'tidak_aktif' },
      ]},
      { key: 'status', componentType: "select", options: [
        { label: 'Semua', value: '' },
        { label: 'Aktif', value: 'aktif' },
        { label: 'Non-Aktif', value: 'tidak_aktif' },
      ]},
      { key: 'timezone', componentType: "select", options: [
        { label: 'Semua', value: '' },
        { label: 'WIB', value: 'WIB' },
        { label: 'WITA', value: 'WITA' },
        { label: 'WIT', value: 'WIT' },
      ]},
      
    ],
    DEFAULT_EMPTY_DATA_SOURCE: [
      {
        key: 'text',
        user_name: '',
        employee_name: '',
        job_name: '',
        user_type: '',
        is_locked: '',
        status: '',
        timezone: ''
      },
    ],
  }