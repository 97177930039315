import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';
import getEmployeeMe from '../../../../../@core/interaction/employee/GetEmployeeMe.interaction';

export default class AddSubmissionLoanUsecase {
  create(body) {
    const URL = Constant.URL_BASE_LOAN + '/v3/request/loan';
    return API.post(URL, body);
  }

  getEmployeeMe() {
    return getEmployeeMe().then((res: any) => {
      return res.data.content;
    });
  }

  getConfigLoan() {
    const params = { is_active: true }
    const URL = Constant.URL_BASE_LOAN + '/v3/lov/config';
    return API.get(URL, { params }).then((res) => {
      const content = res.data.content;
      const result = content?.map((config) => {
        return {
          key: config.config_loan_id,
          value: config.config_loan_id,
          label: config.config_name,
          loan_plafon: config.loan_plafon,
          loan_interest: config.loan_interest,
          loan_rule: config.loan_rule,
          max_installment: config.max_installment,
        };
      });
      return result
    });
  }

  getPlafonLoan(params) {
    const URL = Constant.URL_BASE_LOAN + '/v3/check';
    return API.get(URL, { params });
  }
}
