/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'antd/lib/form/Form';
import UploadBannerTemplate from '../template/UploadBannerTemplate';
import { useEffect, useState } from 'react';
import { DetailBannerType } from '../template/UploadBannerTemplate.interface';
import { DetailBannerUsecase } from './DetailBanner.usecase';
import { ToolbarDetailActionButton } from '../../../hr/component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface';
import uploadImage from '../../../../@core/interaction/asset/UploadImage.interaction';
import moment from 'moment';
import ToastService from '../../../../services/Toast.service';
import { BodyUploadBannerType } from '../upload-banner/UploadBanner.interface';
import LocalNotificationService from '../../../../services/LocalNotification.service';

const usecase = new DetailBannerUsecase();
const toastService = new ToastService();
const localNotificationService = new LocalNotificationService()

export default function DetailBanner({ match }) {
  const bannerId = match.params.bannerId

  const [form] = useForm();

  const [viewMode, setViewMode] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [listPosition, setListPosition] = useState([]);
  const [dataSource, setDataSource] = useState<DetailBannerType>()

  useEffect(() => {
    init()
  }, [])

  function init() {
    usecase.getDetail(bannerId).then((res) => {
      const content = res.data.content
    
      setDataSource({
        content: content.content,
        end_date: moment(content.end_date),
        start_date: moment(content.start_date),
        name: content.name,
        position: content.position,
        upload_file: { img: content.image_url },
        redirect_link: content.redirection,
      })
    })

    usecase.getPosition().then(
      (res) => {
        const content = res.data.content
        setListPosition(content)
        setIsLoading(false)
      }
    )
  }

  const submitButton = { active: !viewMode, disabled: isLoading };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'EDIT',
    active: viewMode,
    disabled: false,
    onClick: () => {
      setViewMode(false)
    },
  };

  const secondActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: false,
    onClick: () => {
      if (!viewMode) {
        window.location.reload()
      } else {
        const back = window.confirm("Anda Ingin Kembali")
        if (back) {
          window.location.href = "/v2/app/admin/banner-list"
        }
      }
    },
  };

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Banner Promosi',
        href: '/v2/app/admin/banner-list',
      },
      {
        clickable: false,
        displayPathName: 'Upload',
        href: '/v2/app/admin/banner/new/',
      },
    ],
  };

  const onFieldsChange = (e) => {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;

      switch (fieldName) {
        default:
          break;
        case 'upload-file':
          form.setFieldsValue({ img: value.file || null })
          break;
      }
    }
  };

  function getBase64(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        resolve(error);
      };
    });
  } 
  const onFinish = async (e) => {
    setIsLoading(true)
    let file_image: any    
    if (e.upload_file?.file) {
      file_image = await getBase64(e.upload_file?.file)
    } else {
      file_image = null
    }

    const body: BodyUploadBannerType = {
      name: e.name,
      file_image: file_image?.split("base64,").pop(),
      position: e.position,
      action_type: 2,
      redirection: e.redirect_link,
      platform_type: 1,
      content: e.content,
      start_date: moment(e.start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment(e.end_date).format("YYYY-MM-DD HH:mm:ss"),
      is_active: true,
      is_repeated: false,
    };

    usecase.update(body, bannerId).then(
      (res) => {
        const data = res.data
        if (data.status === 200) {
          localNotificationService.openSuccess("Data berhasil diubah.", "", 1)
        }
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    ).catch(err => {
      const message = err?.data?.error?.message
      toastService.error(message)
      setIsLoading(false)
    })
  };

  return (
    <div className="upload-banner">
      <UploadBannerTemplate 
        breadcrumbConf={breadcrumbConf}
        submitButton={submitButton}
        firstActionButton={firstActionButton}
        secondActionButton={secondActionButton}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        dropdownActionButton={{ visible: false }}
        listPosition={listPosition}
        viewMode={viewMode}
        onFieldChange={onFieldsChange}
        detailDataSource={dataSource}
        onFinish={onFinish}
        isLoading={isLoading}
      />
    </div>
  );
}
