import Constant from "../../../../config/Constants";
import API from "../../../../services/API.service";

export default class ListBannerUseCase {
  active(body) {
    const URL = Constant.URL_BASE_MISC + '/v3/ssa/banner/active';
    return API.put(URL, body);
  }

  getAll(params) {
    const URL = Constant.URL_BASE_MISC + '/v3/ssa/banner';
    return API.get(URL, { params });
  }
}
