import { useEffect } from 'react';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import { Form } from 'antd';
import ToolbarAttendanceDetailTemplate from './components/toolbar-attendance-detail-template/ToolbarAttendanceDetailTemplate';
import EmployeeAttendanceDetailForm from './components/employee-attendance-detail-form/EmployeeAttendanceDetailForm';
import BottomLoading from '../../../../../components/bottom-loading/BottomLoading';
import './EmployeeAttendanceDetailTemplate.less';

export default function EmployeeAttendanceDetailTemplate(props: any) {
  
  const [form] = Form.useForm();
  useEffect(() => {
    if (props.formInitEvent) {
      setTimeout(() => {
        props.formInitEvent(form.getFieldsValue());
      }, 50);
    }
  }, []);

  const onFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue());
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (props.onFieldChange) {
      props.onFieldChange(changedFields, form);
    }
  };

  return (
    <div id="employee-leave-detail-template">
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <Form
        name="basic"
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 6 }}
        layout="horizontal"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
      >
        <ToolbarAttendanceDetailTemplate
          submitButton={props.submitButton}
          firstActionButton={props.firstActionButton}
          secondActionButton={props.secondActionButton}
          thirdActionButton={props.thirdActionButton}
          fourthActionButton={props.fourthActionButton}
        />

        <div className="scrollable-content-detail_attendance">
          <div className="form-wrapper">
            <EmployeeAttendanceDetailForm
              employee={props.employee}
              absentStatus={props.absentStatus}
              reason={props.reason}
              checkin={props.checkin}
              checkout={props.checkout}
              statusCheckout={props.statusCheckout}
              workedHours={props.workedHours}
              listDetail={props.listDetail}
              checkinLocation={props.checkinLocation}
              checkoutLocation={props.checkoutLocation}
              isEditMode={props.isEditMode}
              checkinRadius={props.checkinRadius}
              checkoutRadius={props.checkoutRadius}
              geoFencing={props.geoFencing}
            />
          </div>
        </div>
      </Form>

      <BottomLoading active={props.isLoading} />
    </div>
  );
}
