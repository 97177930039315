import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Form, Button, Space, Input, Modal, DatePicker, Table } from 'antd';
import FormBuilder from 'antd-form-builder';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import LandingListTemplate from '../../../../../templates/landing-list-template/LandingListTemplate';
import { LandingListTemplatePagination } from '../../../../../templates/landing-list-template/LandingListTemplate.interface';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import ListClaimPayment from './ListClaimPayment.usecase';
import { ClaimPaymentConstant } from './ListClaimPayment.constant';
import { aesGcmDecrypt } from '../../../../../services/Encrypter';

const { RangePicker } = DatePicker;

const useCaseImpl = new ListClaimPayment();
const localNotificationService = new LocalNotificationService();

let params: any = {};
let page = 1;
let loadPagination = true;
let selectionClaimId = [];

export default function HrListClaimPayment() {
  const history = useHistory();
  const [form] = Form.useForm();

  const tableHeader: any = ClaimPaymentConstant.TABLE_HEADER;
  const searchColumnKey = ClaimPaymentConstant.SEARCH_COLUMN_KEY;
  const searchColumnComponent = ClaimPaymentConstant.SEARCH_COLUMN_COMPONENT;
  const limit = 50;
  let [pagination, setPagination] = useState<LandingListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  let [tableScroll, setTableScroll] = useState({ y: 200 });
  const [employeeList, setEmployeeList] = useState([]);
  const [typeOption, setTypeOption] = useState([]);
  let [dataSource, setDataSource] = useState([]);
  const [showList, setShowList] = useState(false);
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalLampiran, setShowModalLampiran] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState([]);
  const [selectedLampiran, setSelectedLampiran] = useState();
  const [selectedAmount, setSelectedAmount] = useState();
  let [isLoading, setIsLoading] = useState(true);

  const tableData = {
    searchColumnKey,
    dataSource,
    tableRowSelectionActive: true,
    searchColumnComponent,
  };

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Pembayaran Klaim',
      },
    ],
  };

  const PeriodComp = ({ value, onChange }) => {
    return (
      <div>
        <RangePicker
          defaultValue={[value.start, value.end]}
          onChange={(v) => onChange({ start: v[0], end: v[1] })}
          style={{ width: '96%' }}
          format="DD-MM-YYYY"
        />
      </div>
    );
  };

  const meta: any = {
    columns: 1,
    fields: [
      {
        key: 'period',
        label: 'Periode',
        widget: PeriodComp,
        required: true,
        initialValue: { start: null, end: null },
      },
      {
        key: 'claim_id',
        label: 'Tipe Klaim',
        widget: 'select',
        forwardRef: true,
        options: typeOption,
        required: true,
        message: 'Tipe Klaim harus dipilih',
      },
      {
        key: 'no_claim',
        label: 'No Klaim',
      },
      {
        key: 'employee',
        label: 'Karyawan',
        widget: 'select',
        options: employeeList,
        widgetProps: {
          mode: 'multiple',
        },
      },
      {
        key: 'state_payment',
        label: 'Status Pembayaran',
        widget: 'select',
        options: [
          { value: 'belum_dibayar', label: 'Belum Dibayar' },
          { value: 'dibayar', label: 'Dibayar' },
          { value: '', label: 'Semua' },
        ],
        initialValue: 'belum_dibayar',
      },
    ],
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    const getType = await useCaseImpl.getClaimType();
    const employeeList = await useCaseImpl.getEmployeeList();

    setTypeOption(getType);
    setEmployeeList(employeeList);
    setIsLoading(false);
  }

  async function onFinish(e) {
    const list_employee =
      e.employee && e.employee.map((item) => JSON.parse(item)).map((row) => row.id);
    const claim_type = e.claim_id && JSON.parse(e.claim_id).id;
    params = {
      with_meta: true,
      page: page,
      limit: limit,
      start_date: e.period.start && moment(e.period.start).format('YYYY-MM-DD'),
      end_date: e.period.end && moment(e.period.end).format('YYYY-MM-DD'),
      claim_config_id: claim_type,
      no_claim: e.no_claim,
      employee: list_employee,
      state_payment: e.state_payment,
    };
    loadList(params);
    setShowList(true);
  }

  async function loadList(e) {
    setIsLoading(true);
    useCaseImpl.getClaimListPayment(e).then(
      (res: any) => {
        setIsLoading(false);
        const content = res?.content;
        const metaData = res?.metaData;
        const resultDataSource =
          content && content.length ? content : ClaimPaymentConstant.DEFAULT_EMPTY_DATA_SOURCE;
        setDataSource([...resultDataSource]);
        updatePaginationState(metaData?.total_records);

        loadPagination = true;
      },
      (err) => {
        setIsLoading(false);
        setDataSource([...ClaimPaymentConstant.DEFAULT_EMPTY_DATA_SOURCE]);
        loadPagination = true;
      },
    );
  }

  async function onSave() {
    setShowModalPassword(true);
  }
  async function onSubmit(value) {
    const body = {
      ids: selectionClaimId,
      password: value.password,
    };
    const e = form.getFieldsValue();
    const list_employee =
      e.employee && e.employee.map((item) => JSON.parse(item)).map((row) => row.id);
    const claim_type = e.claim_type && JSON.parse(e.claim_type).id;
    useCaseImpl.updateClaimPayment(body).then(
      (res) => {
        if (res?.data?.status) {
          localNotificationService.openSuccess(res?.data?.message, '', 3);
          params = {
            with_meta: true,
            page: page,
            limit: limit,
            start_date: e.period.start && moment(e.period.start).format('YYYY-MM-DD'),
            end_date: e.period.end && moment(e.period.end).format('YYYY-MM-DD'),
            claim_config_id: claim_type,
            no_claim: e.no_claim,
            employee: list_employee,
            state_payment: '',
          };
          loadList(params);
          form.setFieldsValue({
            password: '',
          });
          setShowModalPassword(false);
        }
      },
      (err) => {
        localNotificationService.openError(err, '', 3);
      },
    );
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputAction = output.tableAction;
    const outputSelection = output.selection;
    processAction(outputAction);
    processSelection(outputSelection);
  };

  async function processAction(outputAction) {
    if (!!outputAction?.id) {
      setSelectedAmount(outputAction.amount);
      const tempDetail = await useCaseImpl.getClaimDetailPayment(outputAction.id);
      if (!!tempDetail.content) {
        decriptImage(tempDetail.content);
      }
    }
  }

  async function decriptImage(source) {
    const temp = await Promise.all(
      !!source &&
        source.map(async (item) => {
          let tempFoto = null;
          if (item.image) {
            tempFoto = await aesGcmDecrypt(item.image);
          }
          return {
            ...item,
            lampiran: item.image ? 'Tampilkan' : '',
            decryptedFoto: tempFoto,
          };
        }),
    );
    setSelectedDetail(temp);
    setShowModalDetail(true);
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionClaimId) {
      selectionClaimId = outputSelection;
    }
  }

  return (
    <div style={{ position: 'relative', height: 'calc(100vh - 100px)' }}>
      {showModalPassword && (
        <Modal
          open={showModalPassword}
          title="Masukkan Password"
          okText="Submit"
          cancelText={null}
          okButtonProps={{ autoFocus: true, htmlType: 'submit' }}
          onCancel={() => {
            form.setFieldsValue({
              password: '',
            });
            setShowModalPassword(false);
          }}
          destroyOnClose
          modalRender={(dom) => (
            <Form layout="vertical" form={form} onFinish={(values) => onSubmit(values)}>
              {dom}
            </Form>
          )}
        >
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Password harus diisi' }]}
          >
            <Input.Password />
          </Form.Item>
        </Modal>
      )}

      {showModalDetail && (
        <Modal
          open={showModalDetail}
          title="Detail Klaim"
          okText="Tutup"
          cancelText={null}
          onOk={() => setShowModalDetail(false)}
          onCancel={() => setShowModalDetail(false)}
          cancelButtonProps={{ style: { display: 'none' } }}
          destroyOnClose
          width={'60rem'}
        >
          <Table
            columns={[
              { title: 'Tanggal Mulai', key: 'date_start', dataIndex: 'date_start', width: 150 },
              { title: 'Tanggal Berakhir', key: 'date_end', dataIndex: 'date_end', width: 150 },
              { title: 'Keterangan', key: 'description', dataIndex: 'description', width: 120 },
              { title: 'Total', key: 'total', dataIndex: 'total', width: 120 },
              {
                title: 'Lampiran',
                key: 'lampiran',
                dataIndex: 'lampiran',
                width: 120,
                render: (value, record) => {
                  return (
                    <Button
                      type="link"
                      onClick={() => {
                        setSelectedLampiran(record.decryptedFoto);
                        setShowModalLampiran(true);
                      }}
                    >
                      {value}
                    </Button>
                  );
                },
              },
            ]}
            dataSource={selectedDetail}
            pagination={false}
            summary={() => {
              return (
                <>
                  <Table.Summary.Row style={{ fontWeight: 'bold', background: 'grey' }}>
                    <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>{selectedAmount}</Table.Summary.Cell>
                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Modal>
      )}

      {showModalLampiran && (
        <Modal
          centered
          visible={showModalLampiran}
          onCancel={() => setShowModalLampiran(false)}
          footer={null}
          width={'60rem'}
        >
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <img src={selectedLampiran} width="95%" />
          </div>
        </Modal>
      )}

      <div style={{ border: '1px solid #000', padding: 10, overflowY: 'scroll', height: '80vh' }}>
        <Form form={form} layout="horizontal" onFinish={onFinish} onValuesChange={forceUpdate}>
          <div>
            <div className="form-wrapper">
              <div>
                <Breadcrumb breadcrumbConf={breadcrumbConf} />
                <div style={{ padding: 10, overflowY: 'scroll', height: 'max-content' }}>
                  <FormBuilder form={form} meta={meta} />
                </div>
                <Form.Item className="form-footer" style={{ width: '100%', marginTop: 20 }}>
                  <Space>
                    <Button type="primary" onClick={() => form.submit()} disabled={isLoading}>
                      Tampilkan
                    </Button>
                    <Button
                      type="default"
                      onClick={() => form.submit()}
                      // disabled={isLoading}
                    >
                      Reset
                    </Button>
                  </Space>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
        {showList && (
          <>
            <Button type="primary" onClick={() => onSave()} disabled={isLoading}>
              Simpan
            </Button>
            <LandingListTemplate
              addButton={{ visible: false }}
              deleteButton={{ visible: false }}
              exportButton={{ visible: false }}
              tableHeader={tableHeader}
              tableData={tableData}
              tablePagination={pagination}
              tableOnChange={tableOnChange}
              isLoading={isLoading}
              tableScroll={tableScroll}
            />
          </>
        )}
      </div>
    </div>
  );
}
