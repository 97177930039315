import React, { useEffect, useState } from 'react';
import { Form, Button, DatePicker, Upload, Space, Tag, Modal, Table } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import type { RcFile } from 'antd/es/upload/interface';
import FormBuilder from 'antd-form-builder';
import ReactQuill from 'react-quill';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import PageLoader from '../../../../../components/loader/PageLoader';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import UpdateNews from './UpdateNews.usecase';
import { aesGcmDecrypt } from '../../../../../services/Encrypter';
import './UpdateNews.less';
const { RangePicker } = DatePicker;

const useCaseImpl = new UpdateNews();
const localNotificationService = new LocalNotificationService();
let newsId = '';
let jobModalDS;
let employeeModalDS;
export default function HrAddNews({ match }) {
  const history = useHistory();
  newsId = match.params.newsId;
  const [form] = Form.useForm();
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [initialData, setInitialData] = useState<any>({});
  const [employeeList, setEmployeeList] = useState([]);
  const [jobList, setJobList] = useState([]);
  let [isLoading, setIsLoading] = useState(true);
  const [contentEditor, setContentEditor] = useState<any>('');
  const [fileList, setFileList] = useState([]);
  const [showModalJob, setShowModalJob] = useState<any>(false);
  const [showModalEmployee, setShowModalEmployee] = useState<any>(false);
  const [modalLoading, setModalLoading] = useState(true);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Kirim Berita',
        href: '/v2/app/hr-news/news-list',
      },
      {
        clickable: false,
        displayPathName: initialData.name,
      },
    ],
  };

  const JOB_TABLE_HEADER = [
    {
      title: 'Jabatan',
      dataIndex: 'job_name',
    },
    {
      title: 'Departement',
      dataIndex: 'dept_name',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (value, result) => {
        return (
          <DeleteOutlined
            onClick={(e) => {
              e.preventDefault();
              const param: any = {
                ids: [result.relId],
                news_type: 'job',
              };
              const URL = Constant.URL_BASE_COMPANY + `/v3/news/${newsId}/rel`;
              return API.delete(URL, { data: param }).then((res: any) => {
                setModalLoading(true);
                initialPage();
              });
            }}
          />
        );
      },
    },
  ];

  const EMPLOYEE_TABLE_HEADER = [
    {
      title: 'NIK',
      dataIndex: 'nik',
    },
    {
      title: 'Nama',
      dataIndex: 'name',
    },
    {
      title: 'Jabatan',
      dataIndex: 'job_id',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (value, result) => {
        return (
          <DeleteOutlined
            onClick={(e) => {
              e.preventDefault();
              const param: any = {
                ids: [result.relId],
                news_type: 'employee',
              };
              const URL = Constant.URL_BASE_COMPANY + `/v3/news/${newsId}/rel`;
              return API.delete(URL, { data: param }).then((res: any) => {
                setModalLoading(true);
                initialPage();
              });
            }}
          />
        );
      },
    },
  ];

  const disabledDate = (current) => {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    return current && current.valueOf() < date;
  };

  const PeriodComp = ({ value, onChange }) => {
    return (
      <div>
        <RangePicker
          defaultValue={[moment(value.start, 'DD-MMM-YYYY'), moment(value.end, 'DD-MMM-YYYY')]}
          onChange={(v) => onChange({ start: v[0], end: v[1] })}
          style={{ width: '96%' }}
          format="DD-MMM-YYYY"
          disabledDate={disabledDate}
          allowClear={false}
        />
      </div>
    );
  };

  const meta: any = {
    columns: 1,
    fields: [
      {
        key: 'name',
        label: 'Nama Berita',
        rules: [{ required: true, whitespace: true, message: 'Nama Berita harus diisi' }],
      },
      {
        key: 'period',
        label: 'Tanggal Tayang',
        widget: PeriodComp,
        initialValue: { start: initialData.start, end: initialData.end },
        required: true,
        message: 'Tanggal Tayang harus diisi',
      },
      {
        key: 'config_type',
        label: 'Berlaku Untuk',
        widget: 'radio-group',
        disabled: true,
        forwardRef: true,
        options: [
          { value: 'all_employee', label: 'Semua Karyawan' },
          { value: 'job', label: 'Jabatan Tertentu' },
          { value: 'employee', label: 'Karyawan Tertentu' },
        ],
      },
    ],
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    const getJob = await useCaseImpl.getJob();
    const employeeList = await useCaseImpl.getEmpl();
    const newsDetail: any = await useCaseImpl.getNewsDetail(newsId);
    let inital_job: any = [];
    let inital_employee = [];
    if (newsDetail.view_list_job) {
      inital_job = getJob.reduce((acc, val, i) => {
        if (newsDetail.view_list_job.map((item) => item.job_id).includes(val.key)) {
          const temp = newsDetail.view_list_job.find((items: any) => items.job_id === val.key);
          acc.push({
            ...JSON.parse(val.value),
            relId: temp.id,
            key: temp.id,
          });
        }

        return acc;
      }, []);
    }
    if (newsDetail.view_list_emp) {
      inital_employee = employeeList.reduce((acc, val, i) => {
        if (newsDetail.view_list_emp.map((items: any) => items.employee_id).includes(val.key)) {
          const temp = newsDetail.view_list_emp.find((items: any) => items.employee_id === val.key);
          acc.push({
            ...JSON.parse(val.value),
            relId: temp.id,
            key: temp.id,
          });
        }
        return acc;
      }, []);
    }

    jobModalDS = inital_job && inital_job;
    employeeModalDS = inital_employee && inital_employee;

    let tempInitial = {
      name: newsDetail.name,
      config_type: newsDetail.news_type,
      start: moment(newsDetail.start_date).format('DD-MMM-YYYY'),
      end: moment(newsDetail.expired_date).format('DD-MMM-YYYY'),
      current_job: inital_job,
      current_employee: inital_employee,
    };
    if (newsDetail.view_list_lampiran) {
      decriptFile(newsDetail.view_list_lampiran);
    }

    setContentEditor(newsDetail.description);
    setJobList(getJob);
    setEmployeeList(employeeList);
    setInitialData(tempInitial);
    setIsLoading(false);
    setModalLoading(false);
  }

  async function decriptFile(source) {
    const temp = await Promise.all(
      !!source &&
        source.map(async (item) => {
          let tempFoto = null;
          if (item.file) {
            tempFoto = await aesGcmDecrypt(item.file);
          }
          return {
            uid: item.id,
            name: item.file_name,
            status: 'done',
            url: tempFoto,
          };
        }),
    );
    setFileList(temp);
  }

  const beforeUpload = (file: RcFile) => {
    const isLt3M = file.size / 1024 / 1024 < 3;
    if (!isLt3M) {
      localNotificationService.openError('File Harus Lebih Kecil dari 3MB', '', 3);
    }
    return !isLt3M;
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  async function onFinish(e) {
    setIsLoading(true);
    const list_job =
      e.job &&
      e.job
        .map((item) => JSON.parse(item))
        .map(function (row) {
          return {
            job_id: row.id,
            name: row.job_name,
            dept_name: row.dept_name,
            dept_id: row.dept_id,
          };
        });
    const list_employee =
      e.employee &&
      e.employee
        .map((item) => JSON.parse(item))
        .map(function (row) {
          return {
            employee_id: row.id,
            nik: row.nik,
            name: row.name,
            alternative_name: row.alternative_name,
            job_id: row.job_id,
            job_name: row.job_name,
            dept_id: row.dept_id,
            dept_name: row.dept_name,
          };
        });
    let tempParam: any = {};
    e.employee && (tempParam['list_emp'] = list_employee);
    e.job && (tempParam['list_job'] = list_job);
    let isFileValid = true;
    let finalFile = []
    if (fileList) {
    const temp = await Promise.all(
      fileList.map(async (item) => {
        if (item.size > 3000000) {
          isFileValid = false;
          return;
        }
        if (item.status !== 'done') {
          if (item.type == 'application/pdf') {
            const base64Result: any = await getBase64(item.originFileObj);
            return {
              file_name: item.name,
              file: `${base64Result.split(',')[1]}`
            };
          } else {
            return {
              file_name: item.name,
              file: item.thumbUrl.split(',')[1],
            }
          }
        }
      })
    );
    finalFile = temp.reduce((acc, val, i) => {
      if (!!val) {
        acc.push({
          ...val
        });
      }
      return acc;
    }, []);
     
    }
    if (!isFileValid) {
      localNotificationService.openError('File Harus Lebih Kecil dari 3MB', '', 3);
      setIsLoading(false);
      return;
    }
    const params = {
      id: newsId,
      name: e.name.trim(),
      description: contentEditor,
      start_date: e.period.start && moment(e.period.start).format('YYYY-MM-DD'),
      expired_date: e.period.end && moment(e.period.end).format('YYYY-MM-DD'),
      news_type: e.config_type,
      list_lampiran: finalFile.length ? finalFile : [],
      ...tempParam,
    };
    useCaseImpl.updateNews(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          history.push('/app/hr-news/news-list');
          localNotificationService.openSuccess('Berita Berhasil Diubah', '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  let newMeta = meta;
  if (form.getFieldValue('config_type')) {
    if (form.getFieldValue('config_type') === 'employee') {
      newMeta.fields[newMeta.fields.length + 1] = {
        key: 'employee',
        label: 'Karyawan',
        message: 'Karyawan harus diisi',
        widget: 'select',
        options: employeeList,
        widgetProps: {
          mode: 'multiple',
        },
      };
      newMeta.fields[newMeta.fields.length + 2] = {
        key: 'current_employee',
        label: 'Karyawan yang sudah dipilih',
        readOnly: true,
        viewWidget: (employee) => {
          const list_employee = employee.value;
          return (
            <Space direction="vertical" style={{ width: '70%' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {list_employee &&
                  list_employee.map((item, i) => {
                    return (
                      <div style={{ marginBottom: 10 }} key={i}>
                        <Tag color="red">
                          <span key={item.id}>{item.name}</span>
                        </Tag>
                      </div>
                    );
                  })}
              </div>
              <div>
                <Button type="link" onClick={() => setShowModalEmployee(true)}>
                  Tampilkan Semua
                </Button>
              </div>
            </Space>
          );
        },
      };
    } else if (form.getFieldValue('config_type') === 'job') {
      newMeta.fields[newMeta.fields.length + 1] = {
        key: 'job',
        label: 'Jabatan',
        widget: 'select',
        options: jobList,
        widgetProps: {
          mode: 'multiple',
        },
      };

      newMeta.fields[newMeta.fields.length + 2] = {
        key: 'current_job',
        label: 'Jabatan yang sudah dipilih',
        readOnly: true,
        viewWidget: (job) => {
          const list_job = job.value && job.value;
          return (
            <Space direction="vertical" style={{ width: '70%' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {list_job &&
                  list_job.map((item, i) => {
                    return (
                      <div style={{ marginBottom: 10 }} key={i}>
                        <Tag color="red">
                          <span key={item.id}>{item.job_name}</span>
                        </Tag>
                      </div>
                    );
                  })}
              </div>
              <div>
                <Button type="link" onClick={() => setShowModalJob(true)}>
                  Tampilkan Semua
                </Button>
              </div>
            </Space>
          );
        },
      };
    }
  } else {
    if (initialData?.config_type === 'employee') {
      newMeta.fields[newMeta.fields.length + 1] = {
        key: 'employee',
        label: 'Karyawan',
        message: 'Karyawan harus diisi',
        widget: 'select',
        options: employeeList,
        widgetProps: {
          mode: 'multiple',
        },
      };

      newMeta.fields[newMeta.fields.length + 2] = {
        key: 'current_employee',
        label: 'Karyawan yang sudah dipilih',
        readOnly: true,
        viewWidget: (employee) => {
          const list_employee = employee.value;

          return (
            <Space direction="vertical" style={{ width: '70%' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {list_employee &&
                  list_employee.map((item, i) => {
                    return (
                      <div style={{ marginBottom: 10 }} key={i}>
                        <Tag color="red">
                          <span key={item.id}>{item.name}</span>
                        </Tag>
                      </div>
                    );
                  })}
              </div>
              <div>
                <Button type="link" onClick={() => setShowModalEmployee(true)}>
                  Tampilkan Semua
                </Button>
              </div>
            </Space>
          );
        },
      };
    }
    if (initialData?.config_type === 'job') {
      newMeta.fields[newMeta.fields.length + 1] = {
        key: 'job',
        label: 'Jabatan',
        widget: 'select',
        options: jobList,
        widgetProps: {
          mode: 'multiple',
        },
      };

      newMeta.fields[newMeta.fields.length + 2] = {
        key: 'current_job',
        label: 'Jabatan yang sudah dipilih',
        readOnly: true,
        viewWidget: (job) => {
          const list_job = job.value;
          return (
            <Space direction="vertical" style={{ width: '70%' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {list_job &&
                  list_job.map((item, i) => {
                    return (
                      <div style={{ marginBottom: 10 }} key={i}>
                        <Tag color="red">
                          <span key={item.id}>{item.name}</span>
                        </Tag>
                      </div>
                    );
                  })}
              </div>
              <div>
                <Button type="link" onClick={() => setShowModalJob(true)}>
                  Tampilkan Semua
                </Button>
              </div>
            </Space>
          );
        },
      };
    }
  }
  function handleChange(html) {
    setContentEditor(html);
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div
      className="add-claim-configuration-page"
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Form
        form={form}
        initialValues={initialData}
        layout="horizontal"
        onFinish={onFinish}
        onValuesChange={forceUpdate}
      >
        <div>
          <div className="form-wrapper">
            <div>
              <Breadcrumb breadcrumbConf={breadcrumbConf} />
              <Modal
                visible={showModalEmployee}
                onCancel={() => setShowModalEmployee(false)}
                footer={null}
              >
                <div>
                  <Table
                    loading={modalLoading}
                    columns={EMPLOYEE_TABLE_HEADER}
                    dataSource={employeeModalDS}
                  />
                </div>
              </Modal>

              <Modal visible={showModalJob} onCancel={() => setShowModalJob(false)} footer={null}>
                <div>
                  <Table
                    loading={modalLoading}
                    columns={JOB_TABLE_HEADER}
                    dataSource={jobModalDS}
                  />
                </div>
              </Modal>
              <Form.Item className="form-footer" style={{ width: '100%', marginTop: 20 }}>
                <Button type="primary" onClick={() => form.submit()} disabled={isLoading}>
                  Simpan
                </Button>
                &nbsp; &nbsp;
                <Button onClick={() => history.push('/app/hr-news/news-list')}>Batal</Button>
              </Form.Item>
              <div
                style={{
                  border: '1px solid #000',
                  padding: 10,
                  overflowY: 'scroll',
                  height: '70vh',
                }}
              >
                <FormBuilder form={form} meta={meta} />
                <div>
                  <p style={{ fontSize: 14, fontWeight: 'bold', color: '#4c4c4c' }}>Isi Berita</p>
                  <ReactQuill
                    theme="snow"
                    onChange={handleChange}
                    value={contentEditor}
                    placeholder={'Isi Berita'}
                  />
                </div>
                <div>
                  <div
                    style={{
                      borderBottom: 'solid 0.5px #e4eaf3',
                      marginBottom: 15,
                      paddingTop: 20,
                    }}
                  />
                  <p style={{ fontSize: 14, fontWeight: 'bold', color: '#4c4c4c' }}>Lampiran</p>
                  <Upload
                    accept="application/pdf,.png,.jpg,.jpeg"
                    listType="picture"
                    maxCount={5}
                    multiple
                    fileList={fileList}
                    beforeUpload={beforeUpload}
                    onChange={(info: any) => {
                      if (info.file) {
                        if (
                          info.file.status === 'removed' &&
                          !info.file.uid.includes('rc-upload')
                        ) {
                          const param: any = {
                            ids: [info.file.uid],
                          };
                          const URL = Constant.URL_BASE_COMPANY + `/v3/news/${newsId}/lampiran`;
                          return API.delete(URL, { data: param }).then((res: any) => {
                            setFileList(info.fileList);
                          });
                        }
                      }
                      if (info.fileList) {
                        setFileList(info.fileList);
                      }
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Upload (Max: 5)</Button>
                  </Upload>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
