import { useEffect, useState } from 'react';
import './JoinWithUs.less';
import RegisterFormTemplate from './template/RegisterFormTemplate';
import JoinWithUsUsecase from './JoinWithUs.usecase';

const usecase = new JoinWithUsUsecase();

export default function JoinWithUs() {
  let [isLoading, setIsLoading] = useState(false);
  let [initPage, setInitPage] = useState(false)
  
  const [listSumEmp, setListSumEmp] = useState<any>([]);
  const [listComType, setListComType] = useState<any>([]);
  const [listComCat, setListComCat] = useState<any>([]);
  const [listParam, setListParam] = useState<any>({});
  
  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setInitPage(true);

    const getSumEmpOpt: any = await usecase.getSumEmployeeOption();
    const getCompCat: any = await usecase.getCompanyCategory();
    const getCompType: any = await usecase.getCompanyType();
    
    setListComType(getCompType?.data?.content)
    setListComCat(getCompCat?.data?.content)
    setListSumEmp(getSumEmpOpt?.data?.content);

    setInitPage(false);
  }

  const onFinish = (e, index) => {
    setListParam({ ...listParam, ...e });
    if (index === 2) {
      setIsLoading(true)
      const tempParam = { ...listParam, ...e }
      const params = {
        province_by_id: tempParam.company_address.province,
        city_by_id: tempParam.company_address.city,
        district_by_id: tempParam.company_address.district,
        village_by_id: tempParam.company_address.subdistrict,
        company_category_id: tempParam.bussiness_category,
        pic: tempParam.name,
        company_type_id: tempParam.enterprices,
        cfg_sum_employee_id: tempParam.total_employee,
        pic_phone_number: tempParam.no_telp,
        email: tempParam.email,
        zip_code: tempParam.company_address.postal_code,
        phone_number: tempParam.company_telp,
        name: tempParam.company_name,
        company_address: tempParam.detail_company_address,
        bank_account_number: tempParam.ocbc_account_number,
        is_terms_conditions: true,
        is_full_tc: true,
        company_code: tempParam.company_code,
      };
      
      usecase.submitCompanyData(params).then(
        (res: any) => {
          const data = res.data;
          if (data.status === 200) {
            window.location.href = '/v2/app/admin/thank-you';
          }
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
        },
      );
    }
  };

  if (initPage) {
    return null
  }

  return (
    <div className="join-with-us">
      {/* <img
        src="https://www.hira-hr.com/hira_homepage/static/img/background/bg-design-1.jpg"
        alt=""
      /> */}
      <div className="register-form-card">
        <h1>JOIN WITH US</h1>
        <RegisterFormTemplate
          onFinish={onFinish}
          listSumEmp={listSumEmp}
          listComCat={listComCat}
          listComType={listComType}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
