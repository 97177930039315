import { useHistory } from 'react-router-dom';
import CardViewListTemplate from '../../../../../templates/card-view-list-template/CardViewListTemplate';
import EmployeeTerminateListUseCase from './EmployeeTerminateList.usecase';
import { useEffect, useState } from 'react';
import { CardViewListTemplatePagination } from '../../../../../templates/card-view-list-template/CardViewListTemplate.interface';
import { EmployeeTerminateListConstant } from './EmployeeTerminateList.constant';
import ToastService from '../../../../../services/Toast.service';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import { getEmployeeTerminateList } from '../../../../../@core/mapper/employee/EmployeeTerminate.mapper';

const usecase = new EmployeeTerminateListUseCase();
const toastService = new ToastService();
const localNotificationService = new LocalNotificationService();
const constant = EmployeeTerminateListConstant;
let page = 1;
let limit = 10;
let sort = 'write_date|desc';
let employee_name;
let nik;
let termination_code;
let job;
let department;
let category_termination;
let active;
let loadPagination = true;
let selectionId = [];

export default function EmployeeTerminateList() {
  const history = useHistory();

  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 600, x: 'max-content' });
  let [disableDeleteButton, setDisableDeleteButton] = useState(true);
  let [pagination, setPagination] = useState<CardViewListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });

  let tableHeader: any = constant.TABLE_HEADER;
  let searchColumnKey = constant.SEARCH_COLUMN_KEY;
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Terminasi Karyawan',
        href: '/v2/app/hr-employee/master-region',
      },
    ],
  };

  useEffect(() => {
    usecase.getCategory().then((res) => {
      searchColumnKey[6].options = res;
    });
    loadList();
  }, []);

  function loadList() {
    setIsLoading(true);
    const params = {
      page,
      limit,
      sort,
      employee_name,
      nik,
      termination_code,
      job,
      department,
      category_termination,
      active,
      with_meta: true,
    };

    usecase.getAll(params).then(
      (res: any) => {
        setIsLoading(false);

        const content = res.data.content;
        const metaData = res.data.metadata;

        const resultDataSource = content?.length
          ? getEmployeeTerminateList(content)
          : constant.DEFAULT_EMPTY_DATA_SOURCE;

        setDataSource(resultDataSource);
        updatePaginationState(metaData.total_records);

        loadPagination = true;
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      {
        key: 'export',
        label: 'Ekspor',
        onClick: () => exportData()
      },
      {
        key: 'non-active',
        label: 'Non-Aktifkan',
        disabled: disableDeleteButton,
        onClick: () => {
          setActive();
        },
      },
    ],
  };

  function setActive() {
    setIsLoading(true);

    const body = { id: selectionId }

    usecase.active(body).then(
      (res: any) => {
        const data = res.data;
        if (data.code === 200) {
          const message = data.message;
          localNotificationService.openSuccess(message, '', 1);
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      (err) => {
        setIsLoading(false);
        toastService.error(err);
      },
    );
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSort(outputSort) {
    if (outputSort && usecase.convertOutputSortToEndPointFormat(outputSort) !== sort) {
      page = 1;
      loadPagination = false;

      sort = usecase.convertOutputSortToEndPointFormat(outputSort);
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;

    if (outputSearchColumn) {
      if (
        outputSearchColumn.name !== undefined &&
        outputSearchColumn.name !== employee_name
      ) {
        employee_name = outputSearchColumn.name;
        isLoadList = true;
      }

      if (
        outputSearchColumn.nomor_induk_karyawan !== undefined &&
        outputSearchColumn.nomor_induk_karyawan !== nik
      ) {
        nik = outputSearchColumn.nomor_induk_karyawan;
        isLoadList = true;
      }

      if (outputSearchColumn.terminate_number !== undefined && outputSearchColumn.terminate_number !== termination_code) {
        termination_code = outputSearchColumn.terminate_number;
        isLoadList = true;
      }

      if (outputSearchColumn.job !== undefined && outputSearchColumn.job !== job) {
        job = outputSearchColumn.job;
        isLoadList = true;
      }

      if (
        outputSearchColumn.department !== undefined &&
        outputSearchColumn.department !== department
      ) {
        department = outputSearchColumn.department;
        isLoadList = true;
      }

      if (outputSearchColumn.terminate_category !== category_termination) {
        category_termination = outputSearchColumn.terminate_category;
        isLoadList = true;
      }

      if (outputSearchColumn.status !== undefined && outputSearchColumn.status !== active) {
        active = outputSearchColumn.status;
        isLoadList = true;
      }
    }

    if (isLoadList) {
      page = 1;
      loadPagination = false;
      loadList();
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionId) {
      selectionId = outputSelection;
      setDisableDeleteButton(!selectionId.length)
    }
  }

  const addButton = {
    onClick: () => {
      history.push('/app/hr-employee/employee-terminate/new/');
    },
    disabled: isLoading,
  };

  const exportData = () => {
    setIsLoading(true);
    const params = {
      ids: selectionId,
      sort,
      employee_name,
      nik,
      termination_code,
      job,
      department,
      category_termination,
      active,
      with_meta: true,
    };

    usecase.export(params).then((resp: any) => {
      setIsLoading(false);
      const content = resp.data.content;
      if (resp.status === 200) {
        const data = content.data;
        let sampleArr = base64ToArrayBuffer(data);
        saveByteArray(content.filename, sampleArr);
      }
    }).catch(e => setIsLoading(false));
  };

  function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte) {
    let blob = new Blob([byte], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    let fileName = reportName;
    link.download = fileName;
    link.click();
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;

    processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  const onRow = (e) => {
    if (e.key !== 'search-input' && e.key !== 'empty-data') {
      history.push('/app/hr-employee/employee-terminate/' + e.key);
    }
  };

  return (
    <div className="employee-terminate-list">
      <CardViewListTemplate
        breadcrumbConf={breadcrumbConf}
        addButton={addButton}
        deleteButton={{ visible: false }}
        dropdownButton={dropdownButton}
        tableData={tableData}
        isOnlyTable={true}
        EditButtonCard={{ visible: false, disabled: false, onClick: null }}
        DeleteButtonCard={{ visible: false, disabled: false, onClick: null }}
        selectedRowKeys={selectionId}
        tableHeader={tableHeader}
        tableOnChange={tableOnChange}
        onRow={onRow}
        tablePagination={pagination}
        tableScroll={tableScroll}
        isLoading={isLoading}
      />
    </div>
  );
}
