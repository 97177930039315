import Constant from "../../../config/Constants";
import API from "../../../services/API.service";
import { companyMapper } from './CompanyApproval.mapper'

export default class CompanyApprovalUsecase {

  getlistCompany(params) {
    const URL = Constant.URL_BASE_REGISTER + '/v3/ssa/reg-company'
    return API.get(URL, { params }).then((res: any) =>
      companyMapper(res.data)
    )
  }

  approvalAction(body) {
    const URL = Constant.URL_BASE_REGISTER + '/v3/ssa/reg-company/approved';
    return API.put(URL, body);
  }

  export(params) {
    const URL = Constant.URL_BASE_REGISTER + "/v3/ssa/reg-company/export"
    return API.get(URL, { params })
  }
}