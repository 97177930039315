import { useMemo, useState } from 'react';
import { Button, Form } from 'antd';
import FormBuilder from 'antd-form-builder';
import { useForm } from 'antd/lib/form/Form';
import './RegisterFormTemplate.less';
import CustomStepsComponent from './components/CustomStepsComponent';
import { FormTemplateConstant } from './RegisterFormTemplate.constant';
import { ItemsSteps } from './components/CustomStepsComponent.interface';
import SelectLocationComponent from './components/SelectLocationComponent';
import ocbcLogo from './assets/ocbc-logo.png';
import CheckboxGroup from './components/CheckboxGroup';

const enumSteps = ['Langkah 1', 'Langkah 2', 'Langkah 3'];

export default function RegisterFormTemplate(props: any) {
  const [form] = useForm();
  // const forceUpdate = React.useCallback(() => updateState({}), []);
  // const [, updateState] = React.useState<any>();
  // const selectRef: React.Ref<RefSelectProps> = useRef();
  const [activeSteps, setActiveSteps] = useState<string>(enumSteps[0]);
  const [items, setItems] = useState<ItemsSteps[]>(FormTemplateConstant.ITEMS);
  // const [listCity, setListCity] = useState<any>([]);
  // const [listDistrict, setListDistrict] = useState<any>([]);
  // const [listSubdistrict, setListSubdistrict] = useState<any>([]);
  const [disabledButtonSave, setDisabledButtonSave] = useState(true);

  useMemo(() => {
    followingChangeActive(activeSteps);
  }, [activeSteps, props]);

  const FIRST_META = {
    columns: 8,
    fields: [
      {
        key: 'enterprices',
        label: 'Badan Usaha',
        widget: 'select',
        required: true,
        colSpan: 3,
        labelCol: { span: 24 },
        formItemLayout: {
          wrapperCol: { span: 20 },
        },
        message: "Badan Usaha harus dipilih.",
        placeholder: 'Silahkan pilih badan usaha',
        options: props.listComType.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        }),
      },
      {
        key: 'company_name',
        label: 'Nama Perusahaan',
        placeholder: 'Masukkan nama perusahaan',
        required: true,
        colSpan: 5,
        labelCol: { span: 24 },
        message: "Nama Perusahaan harus diisi.",
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
      },
      {
        key: 'bussiness_category',
        label: 'Kategori Usaha',
        widget: 'select',
        placeholder: 'Silahkan pilih kategori usaha',
        required: true,
        colSpan: 3,
        labelCol: { span: 24 },
        formItemLayout: {
          wrapperCol: { span: 20 },
        },
        message: "Kategori Usaha harus dipilih",
        options: props.listComCat.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        }),
      },
      {
        key: 'ocbc_account_number',
        label: 'Nomor Rekening OCBC',
        placeholder: 'Masukkan nama perusahaan',
        required: true,
        message: "Nomor Rekening OCBC harus diisi",
        colSpan: 5,
        labelCol: { span: 24 },
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
        widgetProps: {
          prefix: (
            <img
              src={ocbcLogo}
              style={{ width: '24px', height: '24px', filter: 'brightness(120%)' }}
              alt=""
            />
          ),
        },
      },
      {
        key: 'total_employee',
        label: 'Jumlah Karyawan',
        widget: 'select',
        placeholder: 'Silahkan isi Jumlah Karyawan',
        required: true,
        colSpan: 3,
        labelCol: { span: 24 },
        formItemLayout: {
          wrapperCol: { span: 20 },
        },
        message: "Jumlah Karyawan harus dipilih",
        options: props.listSumEmp.map((item) => {
          return {
            label: item.value_limit,
            value: item.id,
          };
        }),
      },
      {
        key: 'company_code',
        label: 'Kode Perusahaan',
        placeholder: 'Masukkan nama perusahaan',
        required: true,
        message: "Kode Perusahaan harus diisi.",
        colSpan: 5,
        labelCol: { span: 24 },
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
        help: '*Gunakan 3-8 huruf atau angka, kode Perusahaan ini akan digunakan saat login',
      },
    ],
  };

  const SECOND_META = {
    columns: 8,
    formItemLayout: { labelCol: { span: 10 }, wrapperCol: { span: 24 } },
    fields: [
      {
        key: 'company_telp',
        label: 'No Telp Perusahaan',
        placeholder: 'Masukan no. telepon',
        help: 'Misalnya: 021999999',
        required: true,
        message: "No Telp Perusahaan harus diisi.",
        colSpan: 3,
        labelCol: { span: 24 },
        formItemLayout: {
          wrapperCol: { span: 20 },
        },
      },
      {
        key: 'company_address',
        label: 'Alamat Perusahaan',
        widget: SelectLocationComponent,
        placeholder: 'Provinsi, Kota, Kecamatan, Kode Pos',
        required: true,
        colSpan: 5,
        labelCol: { span: 24 },
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
        message: "Alamat Perusahaan harus diisi.",
        rules: [
          {
            validator: (rule, value, callback) => {
              return new Promise<void>((resolve, reject) => {
                if (value && !value.postal_code) {
                  reject(new Error('Alamat Perusahaan harus diisi.'))
                } else {
                  resolve();
                }
              })
            }
          }
        ],
        widgetProps: {
          form
        }
      },
      {
        key: 'detail_company_address',
        label: 'Alamat Detail Perusahaan',
        placeholder: 'Masukan nama jalan atau nomor gedung ',
        message: "Alamat Detail Perusahaan harus diisi.",
        required: true,
        colSpan: 8,
        labelCol: { span: 24 },
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
      },
    ],
  };

  const THIRD_META = {
    columns: 8,
    formItemLayout: { labelCol: { span: 10 }, wrapperCol: { span: 24 } },
    fields: [
      {
        key: 'name',
        label: 'Nama',
        placeholder: 'Masukan nama',
        required: true,
        message: "Nama harus diisi.",
        disabled: props.isLoading,
        colSpan: 3,
        labelCol: { span: 24 },
        formItemLayout: {
          wrapperCol: { span: 20 },
        },
      },
      {
        key: 'email',
        label: 'Email',
        placeholder: 'Masukan email',
        required: true,
        message: "Email harus diisi.",
        disabled: props.isLoading,
        colSpan: 5,
        labelCol: { span: 24 },
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
      },
      {
        key: 'no_telp',
        label: 'No Telepon',
        placeholder: 'Masukan nomor telepon',
        required: true,
        message: "No Telepon harus diisi.",
        help: 'Misalnya: 021999999',
        colSpan: 8,
        labelCol: { span: 24 },
        disabled: props.isLoading,
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
      },
      {
        key: 'checkbox-group',
        widget: CheckboxGroup,   
        colSpan: 8,
        labelCol: { span: 24 },
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
        widgetProps: {
          disabled: props.isLoading
        },
        rules: [{ validator: (_, value) => {
          return new Promise((resolve, reject) => {
            if (value && value.length < 2) {
              setDisabledButtonSave(true)
            } else {
              setDisabledButtonSave(false);
              resolve(value);
            }
          });
        }}]
      },
    ],
  };

  const [activeMeta, setActiveMeta] = useState<any>(FIRST_META);
  const ALL_META = [FIRST_META, SECOND_META, THIRD_META];

  function followingChangeActive(activeSteps: string) {
    const copyItems: ItemsSteps[] = items.map((step, index) => {
      if (index < enumSteps.indexOf(activeSteps)) {
        return { ...step, status: 'finish' };
      } else if (step.title === activeSteps) {
        return { ...step, status: 'active' };
      }

      return { ...step, status: 'wait' };
    });

    setItems([...copyItems]);
  }

  const onFinish = (e) => {
    const index = enumSteps.indexOf(activeSteps);
    if (index < 2) {
      setActiveMeta(ALL_META[index + 1]);
      setActiveSteps(enumSteps[index + 1]);
    }

    if (props.onFinish) {
      props.onFinish(e, index);
    }
  };

  const onBack = () => {
    const index = enumSteps.indexOf(activeSteps);
    setActiveMeta(ALL_META[index - 1]);
    setActiveSteps(enumSteps[index - 1]);
  };
  

  return (
    <div className="register-form-template">
      <Form
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        wrapperCol={{ span: 24 }}
        labelWrap={true}
        style={{ width: '100%', height: '100%', position: 'relative' }}
      >
        <div className="progress-form-step">
          <CustomStepsComponent stepsState={items} />
        </div>

        <div className="register-form-layout">
          <FormBuilder form={form} meta={activeMeta} />
        </div>

        <div className="toolbar-button-footer">
          {enumSteps.indexOf(activeSteps) >= 1 && (
            <Button loading={props.isLoading} onClick={onBack} className="toolbar-button" size="large">
              Kembali
            </Button>
          )}
          <Button 
            disabled={enumSteps.indexOf(activeSteps) === 2 && disabledButtonSave}
            loading={props.isLoading}
            className="toolbar-button"
            htmlType="submit" 
            type="primary"
            size="large"
          >
            Lanjutkan
          </Button>
        </div>
      </Form>
    </div>
  );
}
