import React, { useEffect } from 'react';
import Constants from '../../../../../../config/Constants';
import { Button, Card, Col, InputNumber, Modal, Row, Select, Space, Table, Typography } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import HrLeaveService from '../../../HrLeaveService';
import Moment from 'moment';
import { useUserState } from '../../../../../../context/UserContext';
import './HrAddLeaveCorrection.less';
import ToastService from '../../../../../../services/Toast.service';
import { LeftOutlined, DeleteFilled } from '@ant-design/icons';

const { Text } = Typography;
const stylePadding = { padding: '16px' };

const hrLeaveService = new HrLeaveService();
const toastService = new ToastService();

export default function HrLeaveCorrectionAdd() {
  let userState: any = useUserState();

  let [correctionData, setCorrectionData] = React.useState([]);
  let [employees, setEmployees] = React.useState([]);
  let [tableScroll, setTableScroll] = React.useState({ y: 200 });
  let [isFetch, setIsFetch] = React.useState(false);

  useEffect(() => {
    addNewItems();
  }, []);

  const getListEmployee = () => {
    hrLeaveService
      .getListEmployees(1, 1000)
      .then((response: any) => {
        if (response.status === Constants.RESP_OK) {
          setEmployees(response.data.content);
        }
      })
      .catch((error) => {
        toastService.error(error);
      });
  };

  const getEmployeeLeaveType = (corrID, employeeID) => {
    hrLeaveService
      .getList(1, 100, null, employeeID, "terbatas")
      .then((response: any) => {
        if (response.status === Constants.RESP_OK) {
          let corr = [...correctionData];
          const corrIdx = corr.findIndex((corr) => corr._id === corrID);
          if (corr[corrIdx]) {
            if (response.data.content.length > 0) {
              corr[corrIdx].leaves = response.data.content;
            } else {
              corr[corrIdx].error = 'karyawan tidak memiliki tipe cuti.';
            }
          }
          setCorrectionData(corr);
        }
      })
      .catch((error) => {
        toastService.error(error);
      });
  };

  const getListRequest = (corrID, leaveTypeID, employeeID) => {
    hrLeaveService
      .getListRequest(1, 1000, leaveTypeID, employeeID)
      .then((response: any) => {
        if (response.status === Constants.RESP_OK) {
          // set error when user already has leave request
          const content = response.data.content;
          if (content.length > 0) {

            for (let i = 0; i < content.length; i++) {
              // search state draft or approve, if exist create error
              if (content[i].state === 'draft' || content[i].state === 'approve2') {
                let corr = [...correctionData];
                const corrIdx = corr.findIndex((corr) => corr._id === corrID);
                if (corr[corrIdx]) {
                  corr[corrIdx].error = 'Karyawan masih ada pengajuan.';
                }
                setCorrectionData(corr);
                break;
              }
            }
          }
        }
      })
      .catch((error) => {
        toastService.error(error);
      });
  };

  async function submitChange() {
    const checkCorrection: any = await checkCorrectionData();

    if (checkCorrection.isCorrect) {
      submitChangeToEndPoint();
    } else {
      if (checkCorrection.modalErrorMessage) {
        Modal.error({
          title: 'Koreksi Tidak Dapat Disimpan',
          content: 'Silakan menghapus item yang tidak dapat dikoreksi.',
          onOk() {},
        });
      } else {
        toastService.error(checkCorrection.errorMessage);
      }
    }
  }

  const checkCorrectionData = () => {
    return new Promise((resolve) => {
      const corrData = correctionData;
      if (corrData.length !== 0) {
        for (let i = 0; i < corrData.length; i++) {
          const corr = corrData[i];
          if (!corr.leave_config_id || !corr.employee_id || corr.error) {
            resolve({
              isCorrect: false,
              modalErrorMessage: corr.error ? corr.error : null,
              errorMessage: corr.error ? null : 'Silakan lengkapi data yang akan dikoreksi',
            });
            break;
          }
          if (typeof corr.leave_rem !== 'number') {
            resolve({
              isCorrect: false,
              modalErrorMessage: null,
              errorMessage: 'Koreksi sisa cuti harus di lengkapi',
            });
            break;
          }
          if (corr.leave_rem > corr.leave_plafon) {
            resolve({
              isCorrect: false,
              modalErrorMessage: null,
              errorMessage: 'Koreksi Sisa Cuti tidak boleh melebihi Koresi Plafon',
            });
            break;
          }

          if (i === corrData.length - 1) {
            resolve({ isCorrect: true, modalErrorMessage: null, errorMessage: null });
          }
        } // end of for
      }
    });
  };

  const submitChangeToEndPoint = () => {
    setIsFetch(true);
    hrLeaveService
      .createCorrection(correctionData)
      .then((response: any) => {
        setIsFetch(false);
        if (response.status === Constants.RESP_OK) {
          window.location.href = '/v2/app/hr-leave/correction/';
        }
      })
      .catch((error) => {
        setIsFetch(false);
      });
  };

  const addNewItems = () => {
    setCorrectionData((P) => [...P, { _id: Moment.now().toString() }]);
  };

  const setName = (val, obj) => {
    let corr = [...correctionData];
    const corrIdx = corr.findIndex((corr) => corr._id === obj.cid);
    if (corr[corrIdx]) {
      corr[corrIdx].employee_id = val;
      corr[corrIdx].error = null;
      // reset existing new plafon
      corr[corrIdx].leave_plafon = null;
      corr[corrIdx].leaves = null;
      corr[corrIdx].error = null;
      corr[corrIdx].leave_config_id = null;
      corr[corrIdx].leave_config_emp_id = null;
    }
    setCorrectionData(corr);

    getEmployeeLeaveType(obj.cid, val);
  };

  const setLeaveType = async (val, obj) => {
    let corr = [...correctionData];
    const corrIdx = corr.findIndex((corr) => corr._id === obj.cid);
    if (corr[corrIdx]) {
      getListRequest(corr[corrIdx]._id, val, corr[corrIdx].employee_id);
      
      corr[corrIdx].leave_config_id = val;
      corr[corrIdx].leave_config_emp_id = obj.leave_config_emp_id;
      corr[corrIdx].error = null;
      corr[corrIdx].leave_plafon = null;
      await hrLeaveService
        .getEmployeeLeavePlafon({
          leave_config_id: val,
          employee_id: corr[corrIdx].employee_id,
          leave_config_emp_id: obj.leave_config_emp_id,
        }).then((res) => {
          corr[corrIdx].leave_rem_old = res.data.content.leave_rem_plafon;
          corr[corrIdx].leave_plafon_old = res.data.content.leave_plafon;
          corr[corrIdx].leave_plafon = res.data.content.leave_plafon;
        })
    }
    setCorrectionData(corr);
  };

  const removeSelectedRow = (id) => {
    let corr = [...correctionData];
    setCorrectionData(
      corr.filter((rk) => {
        return rk._id != id;
      }),
    );
  };

  React.useEffect(() => {
    // set scroll table
    const clientHeight = document.getElementById('add-list-correction-page').clientHeight;
    setTableScroll({ y: clientHeight - 320 });

    if (employees.length === 0) {
      getListEmployee();
    }
  }, []);

  return (
    <div id="add-list-correction-page">
      <Row>
        <Col span={1}>
          <a href="/v2/app/hr-leave/correction">
            <LeftOutlined style={{ color: 'red', fontSize: 16, marginTop: '8px' }} />
          </a>
        </Col>
        <Col flex="80%">
          <Title level={4}>Tambah Koreksi Cuti</Title>
        </Col>
        <Col order="right">
          <Button
            type="primary"
            shape="round"
            onClick={submitChange}
            disabled={correctionData.length && !isFetch ? false : true}
          >
            Simpan Perubahan
          </Button>
        </Col>
      </Row>
      <Card>
        <Row>
          <Col flex="20%">Tanggal Koreksi</Col>
          <Col flex="20%">Dikoreksi Oleh</Col>
          <Col flex="60%"></Col>
        </Row>
        <Row>
          <Col flex="20%">{Moment(Moment.now()).format('DD MMMM YYYY')}</Col>
          <Col flex="20%">{userState.auth.login_view}</Col>
          <Col flex="60%"></Col>
        </Row>
        <br />

        <Table
          columns={[
            {
              dataIndex: 'name',
              title: 'Nama Karyawan',
              width: '20%',
              render: (i, row) => {
                return !row ? (
                  ''
                ) : (
                  <Select
                    placeholder="Pilih Nama -"
                    style={{ width: 200 }}
                    showSearch
                    onChange={setName}
                    value={row.employee_id}
                    filterOption={(input:any, option:any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {employees.map((emp, ei) => (
                      <Select.Option
                        key={row._id + 'name' + ei}
                        cid={row._id}
                        value={emp.id}
                        selected={row.employee_id === emp.id}
                        style={{ width: 200 }}
                      >
                        {emp.alternative_name}
                      </Select.Option>
                    ))}
                  </Select>
                );
              },
            },
            {
              dataIndex: 'leave_type',
              title: 'Tipe Cuti',
              width: '20%',
              render: (i, row) => {
                return !row || !row.leaves ? (
                  ''
                ) : (
                  <Select
                    placeholder="Pilih Tipe -"
                    style={{ width: 200 }}
                    showSearch
                    value={row.leave_config_id}
                    onChange={setLeaveType}
                    filterOption={(input:any, option:any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {row.leaves.map((lt, klt) => (
                      <Select.Option
                        key={row._id + 'type' + klt}
                        style={{ width: 200 }}
                        cid={row._id}
                        leave_config_emp_id={lt.leave_config_emp_id}
                        value={lt.id}
                      >
                        {lt.name}
                      </Select.Option>
                    ))}
                  </Select>
                );
              },
            },
            {
              dataIndex: 'leave_plafon_old',
              title: 'Plafon Awal',
              width: '15%',
              render: (i, row) => {
                return !row || !row.leave_config_id ? '' : row.leave_plafon_old;
              },
            },
            {
              dataIndex: 'leave_rem_old',
              title: 'Sisa Plafon Cuti',
              width: '15%',
              render: (i, row) => {
                return !row || !row.leave_config_id ? '' : row.leave_rem_old;
              },
            },
            {
              dataIndex: 'leave_plafon',
              title: 'Koreksi Plafon Awal',
              width: '18%',
              render: (i, row) => {
                return !row ? (
                  ''
                ) : row.error ? (
                  <div>
                    <WarningTwoTone twoToneColor="red" />
                    &nbsp;<Text type="danger">{row.error}</Text>
                  </div>
                ) : !row.leave_config_id ? (
                  ''
                ) : (
                  <InputNumber
                    min={0}
                    value={row.leave_plafon}
                    onChange={(val) => {
                      let corr = [...correctionData];
                      const corrIdx = corr.findIndex((corr) => corr._id === row._id);
                      if (corr[corrIdx]) {
                        corr[corrIdx].leave_plafon = val;
                      }
                      setCorrectionData(corr);
                    }}
                  />
                );
              },
            },
            {
              dataIndex: 'leave_rem',
              title: 'Koreksi Sisa Plafon Cuti',
              width: '18%',
              render: (i, row) => {
                return !row ? (
                  ''
                ) : row.error ? (
                  ''
                ) : !row.leave_config_id ? (
                  ''
                ) : (
                  <InputNumber
                    min={0}
                    max={row.leave_plafon}
                    value={row.leave_rem}
                    onChange={(val) => {
                      let corr = [...correctionData];
                      const corrIdx = corr.findIndex((corr) => corr._id === row._id);

                      if (corr[corrIdx]) {
                        const leave_rem = corr[corrIdx].leave_rem;
                        corr[corrIdx].leave_rem =
                          typeof leave_rem === 'number' && leave_rem % 1 === 0
                            ? Math.ceil(val)
                            : val;
                      }
                      setCorrectionData(corr);
                    }}
                  />
                );
              },
            },
            {
              key: 'action',
              width: '6%',
              render: (i, row) => {
                return (
                  <Space>
                    <a
                      onClick={(e) => {
                        removeSelectedRow(row._id);
                      }}
                    >
                      <DeleteFilled style={{ color: 'gray', fontSize: 16 }} />
                    </a>
                  </Space>
                );
              },
            },
          ]}
          dataSource={correctionData}
          pagination={false}
          scroll={tableScroll}
        />
        <Row>
          <Col flex="25%" style={stylePadding}>
            <Button onClick={addNewItems}> Tambahkan Item Baru</Button>
          </Col>
        </Row>
        <br />
      </Card>
    </div>
  );
}
