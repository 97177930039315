import React, { useEffect, useState } from 'react';
import { Form, Button, DatePicker, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { RcFile } from 'antd/es/upload/interface';
import FormBuilder from 'antd-form-builder';
import ReactQuill from 'react-quill';
import moment from 'moment';
import 'react-quill/dist/quill.snow.css';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import { useHistory } from 'react-router-dom';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import AddNews from './AddNews.usecase';
import './AddNews.less';
const { RangePicker } = DatePicker;

const useCaseImpl = new AddNews();
const localNotificationService = new LocalNotificationService();

export default function HrAddNews() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [employeeList, setEmployeeList] = useState([]);
  const [jobList, setJobList] = useState([]);
  let [isLoading, setIsLoading] = useState(true);
  const [contentEditor, setContentEditor] = useState<any>('');
  const [fileList, setFileList] = useState([]);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Kirim Berita',
        href: '/v2/app/hr-news/news-list',
      },
      {
        clickable: false,
        displayPathName: 'Baru',
      },
    ],
  };

  const disabledDate = (current) => {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    return current && current.valueOf() < date;
  };

  const PeriodComp = ({ value, onChange }) => {
    return (
      <div>
        <Form.Item
          label=""
          name="range_period"
          rules={[{ required: true, message: 'Tanggal Tayang harus diisi' }]}
        >
          <RangePicker
            defaultValue={[value.start, value.end]}
            onChange={(v) => onChange({ start: v[0], end: v[1] })}
            style={{ width: '96%' }}
            format="DD-MMM-YYYY"
            disabledDate={disabledDate}
          />
        </Form.Item>
      </div>
    );
  };

  const meta: any = {
    columns: 1,
    fields: [
      {
        key: 'name',
        label: 'Nama Berita',
        rules: [{ required: true, whitespace: true, message: 'Nama Berita harus diisi' }],
      },
      {
        key: 'period',
        label: 'Tanggal Tayang',
        widget: PeriodComp,
        initialValue: { start: null, end: null },
        required: true,
        message: 'Tanggal Tayang harus diisi',
      },
      {
        key: 'config_type',
        label: 'Berlaku Untuk',
        widget: 'radio-group',
        required: true,
        forwardRef: true,
        options: [
          { value: 'all_employee', label: 'Semua Karyawan' },
          { value: 'job', label: 'Jabatan Tertentu' },
          { value: 'employee', label: 'Karyawan Tertentu' },
        ],
        initialValue: 'all_employee',
      },
    ],
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    const getJob = await useCaseImpl.getJob();
    const employeeList = await useCaseImpl.getEmpl();
    setJobList(getJob);
    setEmployeeList(employeeList);
    setIsLoading(false);
  }

  const beforeUpload = (file: RcFile) => {
    const isLt3M = file.size / 1024 / 1024 < 3;
    if (!isLt3M) {
      localNotificationService.openError('File Harus Lebih Kecil dari 3MB', '', 3);
    }
    return !isLt3M;
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  async function onFinish(e) {
    setIsLoading(true);
    const list_job =
      e.job &&
      e.job
        .map((item) => JSON.parse(item))
        .map(function (row) {
          return {
            job_id: row.id,
            name: row.job_name,
            dept_name: row.dept_name,
            dept_id: row.dept_id,
          };
        });
    const list_employee =
      e.employee &&
      e.employee
        .map((item) => JSON.parse(item))
        .map(function (row) {
          return {
            employee_id: row.id,
            nik: row.nik,
            name: row.name,
            alternative_name: row.alternative_name,
            job_id: row.job_id,
            job_name: row.job_name,
            dept_id: row.dept_id,
            dept_name: row.dept_name,
          };
        });
    let tempParam: any = {};
    e.employee && (tempParam['list_emp'] = list_employee);
    e.job && (tempParam['list_job'] = list_job);

    let isFileValid = true;
    let finalFile = [];
    if (fileList) {
      const temp = await Promise.all(
        fileList.map(async (item) => {
          if (item.size > 3000000) {
            isFileValid = false;
            return;
          }
          if (item.status !== 'done') {
            if (item.type == 'application/pdf') {
              const base64Result: any = await getBase64(item.originFileObj);
              return {
                file_name: item.name,
                file: `${base64Result.split(',')[1]}`,
              };
            } else {
              return {
                file_name: item.name,
                file: item.thumbUrl.split(',')[1],
              };
            }
          }
        }),
      );
      finalFile = temp.reduce((acc, val, i) => {
        if (!!val) {
          acc.push({
            ...val,
          });
        }
        return acc;
      }, []);
    }
    if (!isFileValid) {
      localNotificationService.openError('File Harus Lebih Kecil dari 3MB', '', 3);
      setIsLoading(false);
      return;
    }

    const params = {
      name: e.name.trim(),
      description: contentEditor,
      start_date: e.period.start && moment(e.period.start).format('YYYY-MM-DD'),
      expired_date: e.period.end && moment(e.period.end).format('YYYY-MM-DD'),
      news_type: e.config_type,
      list_lampiran: finalFile.length ? finalFile : [],
      ...tempParam,
    };

    useCaseImpl.submitNews(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          history.push('/app/hr-news/news-list');
          localNotificationService.openSuccess('Berita Berhasil Dibuat', '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  let newMeta = meta;
  if (form.getFieldValue('config_type')) {
    if (form.getFieldValue('config_type') === 'employee') {
      newMeta.fields[newMeta.fields.length + 1] = {
        key: 'employee',
        label: 'Karyawan',
        required: true,
        message: 'Karyawan harus diisi',
        widget: 'select',
        options: employeeList,
        widgetProps: {
          mode: 'multiple',
        },
      };
    } else if (form.getFieldValue('config_type') === 'job') {
      const objIndex = newMeta.fields.findIndex((obj) => obj.key === 'employee');
      newMeta.fields[newMeta.fields.length + 1] = {
        key: 'job',
        label: 'Jabatan',
        required: true,
        message: 'Jabatan harus diisi',
        widget: 'select',
        options: jobList,
        widgetProps: {
          mode: 'multiple',
        },
      };
    }
  }
  function handleChange(html) {
    setContentEditor(html);
  }

  return (
    <div
      className="add-claim-configuration-page"
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Form form={form} layout="horizontal" onFinish={onFinish} onValuesChange={forceUpdate}>
        <div>
          <div className="form-wrapper">
            <div>
              <Breadcrumb breadcrumbConf={breadcrumbConf} />
              <Form.Item className="form-footer" style={{ width: '100%', marginTop: 20 }}>
                <Button type="primary" onClick={() => form.submit()} disabled={isLoading}>
                  Simpan
                </Button>
                &nbsp; &nbsp;
                <Button onClick={() => history.push('/app/hr-news/news-list')}>Batal</Button>
              </Form.Item>
              <div
                style={{
                  border: '1px solid #000',
                  padding: 10,
                  overflowY: 'scroll',
                  height: '70vh',
                }}
              >
                <FormBuilder form={form} meta={meta} />
                <div>
                  <p style={{ fontSize: 14, fontWeight: 'bold', color: '#4c4c4c' }}>Isi Berita</p>
                  <div className="by-br">
                    <ReactQuill
                      theme="snow"
                      onChange={handleChange}
                      value={contentEditor}
                      placeholder={'Isi Berita'}
                    />
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      borderBottom: 'solid 0.5px #e4eaf3',
                      marginBottom: 15,
                      paddingTop: 20,
                    }}
                  />
                  <p style={{ fontSize: 14, fontWeight: 'bold', color: '#4c4c4c' }}>Lampiran</p>
                  <Upload
                    accept="application/pdf,.png,.jpg,.jpeg"
                    listType="picture"
                    maxCount={5}
                    multiple
                    beforeUpload={beforeUpload}
                    onChange={(info: any) => {
                      if (info.fileList) {
                        setFileList(info.fileList);
                      }
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Upload (Max: 5)</Button>
                  </Upload>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
