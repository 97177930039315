import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Form, Button, Row, Col, Radio, Space, InputNumber, Select, Spin, DatePicker } from 'antd';
import FormBuilder from 'antd-form-builder';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import { useHistory } from 'react-router-dom';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import ClaimReport from './ClaimReportFilter.usecase';

const { RangePicker } = DatePicker;


const useCaseImpl = new ClaimReport();
const localNotificationService = new LocalNotificationService();

export default function HrClaimReportFilter() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [, updateState] = React.useState<any>(); const forceUpdate = React.useCallback(() => updateState({}), []);
  const [employeeList, setEmployeeList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [claimConfigList, setClaimConfigList] = useState([]);
  let [isLoading, setIsLoading] = useState(true);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Laporan Klaim',
        href: "/v2/app/hr-claim/employee-claim-report/",
      },
      {
        clickable: false,
        displayPathName: 'Filter',
        href: "",
      }
    ],
  };


  const meta: any = {
    columns: 1,
    fields: [
      {
        key: 'label-text',
        render() {
          return (
            <>
              <p>Silahkan pilih filter dibawah ini sebelum menampilkan data: </p>
            </>
          );
        },
      },
      
      {
        key: 'job',
        label: 'Jabatan',
        widget: 'radio-group',
        forwardRef: true,
        options: [
          { value: 'all', label: 'Tampilkan Semua Jabatan' },
          { value: 'select', label: 'Tampilkan Jabatan Tertentu' },
        ],
        widgetProps: {
          direction: 'vertical'
        },
        initialValue: 'all'
      },
      {
        key: 'employee',
        label: 'Karyawan',
        widget: 'radio-group',
        forwardRef: true,
        options: [
          { value: 'all', label: 'Tampilkan Semua Karyawan' },
          { value: 'select', label: 'Tampilkan Karyawan Tertentu' },
        ],
        initialValue: 'all'
      },
      {
        key: 'claim_config',
        label: 'Konfigurasi Klaim',
        widget: 'radio-group',
        forwardRef: true,
        options: [
          { value: 'all', label: 'Tampilkan Semua Konfigurasi Klaim' },
          { value: 'select', label: 'Tampilkan Konfigurasi Klaim Tertentu' },
        ],
        widgetProps: {
          direction: 'vertical',
        },
        initialValue: 'all'
      },
      {
        key: 'limit_type',
        label: 'Limit Klaim',
        widget: 'radio-group',
        options: [
          { value: '', label: 'Semua' },
          { value: 'terbatas', label: 'Terbatas' },
          { value: 'tidak_terbatas', label: 'Tidak Terbatas' },
        ],
        initialValue: ''
      },
      {
        key: 'is_expired',
        label: 'Data Klaim',
        widget: 'radio-group',
        options: [
          { value: false, label: 'Aktif' },
          { value: true, label: 'Kadaluarsa' },
          { value: '', label: 'Semua' },
        ],
        initialValue: false
      },
    ],
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    const getJob = await useCaseImpl.getJobList();
    const employeeList = await useCaseImpl.getEmployeeList();
    const claimConfigList = await useCaseImpl.getClaimConfigList();
    setJobList(getJob);
    setEmployeeList(employeeList);
    setClaimConfigList(claimConfigList)
    setIsLoading(false);
  }



  async function onFinish(e) {
    const list_job = e.list_job && e.list_job.map(item => JSON.parse(item)).map(row => row.id)
    const list_employee = e.list_employee && e.list_employee.map(item => JSON.parse(item)).map(row => row.id)
    const list_claim_config = e.list_claim_config && e.list_claim_config.map(item => JSON.parse(item)).map(row => row.id)
    setIsLoading(true);
    history.push({
      pathname: "/app/hr-claim/employee-claim-report/",
      state: {
        data: {
          employee: e.employee,
          list_employee: list_employee,
          job: e.job,
          list_job: list_job,
          claim_config: e.claim_config,
          list_claim_config: list_claim_config,
          limit_type: e.limit_type,
          is_expired: e.is_expired
        }
      }
    });
  }

  let newMeta = meta;
  if (form.getFieldValue('job') === 'select') {
    const objIndex = newMeta.fields.findIndex((obj => obj.key === 'job'));
    newMeta.fields.splice(objIndex + 1, 0, {
      key: 'list_job',
      label: 'Pilih Jabatan',
      widget: 'select',
      options: jobList,
      widgetProps: {
        mode: 'multiple'
      },
    });
  }
  if (form.getFieldValue('employee') === 'select') {
    const objIndex = newMeta.fields.findIndex((obj => obj.key === 'employee'));
    newMeta.fields.splice(objIndex + 1, 0, {
      key: 'list_employee',
      label: 'Pilih Karyawan',
      widget: 'select',
      options: employeeList,
      widgetProps: {
        mode: 'multiple'
      },
    });
  }
  if (form.getFieldValue('claim_config') === 'select') {
    const objIndex = newMeta.fields.findIndex((obj => obj.key === 'claim_config'));
    newMeta.fields.splice(objIndex + 1, 0, {
      key: 'list_claim_config',
      label: 'Pilih Konfigurasi Klaim',
      widget: 'select',
      options: claimConfigList,
      widgetProps: {
        mode: 'multiple'
      },
    });
  }

  return (
    <div
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Form form={form} layout="horizontal" onFinish={onFinish} onValuesChange={forceUpdate}>
        <div>
          <div className="form-wrapper">
            <div>
              <Breadcrumb breadcrumbConf={breadcrumbConf} />
              <div style={{ padding: 10, overflowY: 'scroll', height: 'max-content' }}>
                <FormBuilder form={form} meta={meta} />
              </div>
              <Form.Item
                className="form-footer"
                style={{ width: '100%', marginTop: 20 }}
              >
                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  disabled={isLoading}
                >
                  Terapkan
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
