import { useEffect } from "react"
import "./ThankYou.less"
import ThankYouLetter from "./components/ThankYouLetter"

export default function ThankYou() {
  // useEffect(() => {
  //   const header = document.getElementById("nav-header")
  //   header.classList.add("add-bg-nav")
  // }, [])

  return (
    <div className="thank-you-page">
      <ThankYouLetter />
    </div>
  )
}