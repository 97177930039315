import { Col, DatePicker, Row } from "antd"

export const DateTimeBanner = ({ value, onChange, disabled, disabledDate }) => {
  return (
    <Row>
      <Col span={12}>
        <DatePicker 
          showTime 
          value={value} 
          format="YYYY-MM-DD HH:mm"
          onChange={(v) => onChange(v)}
          disabled={disabled}
          disabledDate={disabledDate}
        />
      </Col>
    </Row>
  )
}