import { useEffect, useReducer, useState } from "react";
import { Modal } from "antd";
import { DetailMassLeaveUseCase } from "./DetailMassLeave.usecase";
import { ToolbarDetailActionButton, ToolbarDetailDropdownButton } from "../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import AddMassLeaveTemplate from "../../templates/mass-leave-detail-template/MassLeaveDetailTemplate";
import ToastService from "../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../services/LocalNotification.service";
import { MassLeaveDataSourceType } from "../../templates/mass-leave-detail-template/MassLeaveDetailTemplate.interface";
import { AddMassLeaveUseCase } from "../add-mass-leave/AddMassLeave.usecase";
import { listConfigLeaveMapper, updateMassLeaveMapper } from "../../../../../@core/mapper/leave/MassLeave.mapper";
import moment from "moment";

let historyId = null
let newData = {
  leave_config_id: null,
  reduce_leave_rem: null,
  employee_id: null,
}
const useCaseImpl = new AddMassLeaveUseCase();
const useCase = new DetailMassLeaveUseCase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService()

export default function DetailMassLeave({ match }) {
  const leaveAllId = match.params.leaveAllId

  // useReducer
  const [formState, formDispatch] = useState<MassLeaveDataSourceType>(null)
  const [viewState, viewDispatch] = useReducer(
    (prev, next) => {
      return { ...prev, ...next }
  }, { isLoading: false, viewMode: true, visibleModal: false })

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Cuti Bersama',
        href: "/v2/app/hr-leave/all-request",
      },
      {
        clickable: false,
        displayPathName: formState?.name,
        href: "/v2/app/hr-leave/all-request/new/",
      }
    ],
  };

  useEffect(() => {
    init()
  }, [])

  const submitButton = { active: !viewState.viewMode, disabled: viewState.isLoading };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'UBAH',
    type: 'primary',
    active: viewState.viewMode && formState?.certain_employee,
    disabled: viewState.isLoading,
    onClick: () => {
      viewDispatch({ viewMode: false })
    },
  };

  const secondActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: viewState.isLoading,
    onClick: () => {
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/hr-leave/all-request"
      }
    },
  };

  const dropdownActionButton: ToolbarDetailDropdownButton = {
    disabled: false,
    visible: true,
    options: [
      { 
        key: 'non-aktif', disabled: !formState?.is_active, label: 'Non-Aktifkan', onClick() {
          const params = {
            leave_all_request_ids: [formState.id],
            status: false
          };
      
          useCase.active(params).then(
            (res: any) => {
              viewDispatch({ isLoading: false })
              const content = res.data.content
              if (content.code === 200) {
                const message = content.message.split("- ")[1]
                localNotificationService.openSuccess(message, '', 1)
              }
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            },
            (err) => {
              viewDispatch({ isLoading: false })
              toastService.error(err)
            },
          );
        },
      },
      { 
        key: 'proses_ulang', disabled: false, label: 'Proses Ulang', onClick() {
          const body = {
            id: leaveAllId,
            date_start_leave: moment(formState.date_start_leave).format("YYYY-MM-DD"),
            date_end_leave: moment(formState.date_end_leave).format("YYYY-MM-DD")
          }

          regenerate(body)
        },
      }
    ]
  }

  async function init() {
    viewDispatch({ isLoading: true })
    useCaseImpl.getLeaveConfig().then(
      (res: any) => {
        viewDispatch({ 
          configList: listConfigLeaveMapper(res.data.content) 
        })
    });

    useCase.check(leaveAllId).then(
      (res) => {
        const isProssesingData = !!res.data.content
        viewDispatch({ visibleModal: isProssesingData })
        if (isProssesingData) {
          setTimeout(() => {
            window.location.href = "/v2/app/hr-leave/all-request"
          }, 3000) 
        }
      }
    ).catch(err => {
      
    })

    useCase.getDetail(leaveAllId).then(
      (res) => {
        const content = res.data.content
        formDispatch({ ...content })
      }
    ).catch((err) => {

    })

    useCase.getEmp().then(
      (res) => {
        const data = res.data
        if (data.status === 200) {
          const result = data.content.map((emp) => {
            return { 
              key: emp.id,
              value: emp.id, 
              label: emp.alternative_name,  
            }
          })
          viewDispatch({ 
            isLoading: false,
            employeeList: [...result]
          })
        }
      }
    ).catch(err => {
      viewDispatch({ isLoading: false })
    })
  }

  function regenerate(body) {
    viewDispatch({ isLoading: true })

    useCase.regenerate(body).then(
      (res) => {
        const data = res.data
        if (data.status === 200) {
          const message = data.content.cause
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.reload()
        }, 2500)
      }
    ).catch((err) => {
      toastService.error(err)
      viewDispatch({ isLoading: false })
    })
  }

  function updateData(body) {
    viewDispatch({ isLoading: true })
    
    useCase.update(body).then(
      (res) => {
        const data = res.data
        if (data.status === 200) {
          const message = data.content.cause
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.reload()
        }, 2500)
      }
    ).catch((err) => {
      toastService.error(err)
      viewDispatch({ isLoading: false })
    })
  }

  const onFieldChange = (e) => {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;

      newData[fieldName] = value
    }
  }
  
  const onFinish = (value) => {
    const body = updateMassLeaveMapper(
      formState, value.employee_id || [], 
      value.selected_employees.dataSource
    )
    
    updateData(body)
  }
  
  return (
    <div className="add-mass-leave-page">
      <Modal
        visible={viewState.visibleModal}
        footer={false}
        closable={false}
        centered
        bodyStyle={{
          background: '#d42534',
          color: 'white',
          textAlign: 'center',
          fontSize: '1.8rem',
          fontWeight: '500',
        }}
      >
        Silahkan tunggu beberapa saat, cuti bersama untuk masing-masing 
        karyawan sedang dalam proses pembentukan 
      </Modal>
      <AddMassLeaveTemplate
        breadcrumbConf={breadcrumbConf}
        dataSource={formState}
        firstActionButton={firstActionButton}
        secondActionButton={secondActionButton}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        dropdownActionButton={dropdownActionButton}
        submitButton={submitButton}
        historyId={historyId}
        isDetail={true}
        viewMode={viewState.viewMode}
        employeeList={viewState.employeeList}
        configList={viewState.configList}
        isLoading={viewState.isLoading}
        onFinish={onFinish}
        onFieldChange={onFieldChange}
      />
    </div>
  )
}