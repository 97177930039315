import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";

export default class DetailMySubmissionClaimUsecase {
  active(body) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/request/me/claim/update-state';
    return API.put(URL, body);
  }
  
  getDetail(id) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/request/me/claim/' + id
    return API.get(URL)
  }
  
  getPlafonClaim(params) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/request/claim/check';
    return API.get(URL, { params });
  }
}