import Constant from "../../../../../../config/Constants"
import API from "../../../../../../services/API.service"
import { GetJobListDTO } from '../../../../../../@core/interface/job/GetJobList.interface';
import getJobList from '../../../../../../@core/interaction/job/GetJobList.interaction';
import { jobListToObjById } from '../../../../../../@core/mapper/job/GetJobList.mapper';
import { EmployeeOption } from '../../../../../../@core/entity/Employee.entity';
import { GetEmployeeListDTO } from '../../../../../../@core/interface/employee/GetEmployeeList.interface';
import getEmployeeList from '../../../../../../@core/interaction/employee/GetEmployeeList.interaction';
import createAttendance from '../../../../../../@core/interaction/attendance/CreateAttendance.interaction'
import getAttendanceDetail from '../../../../../../@core/interaction/attendance/GetEmployeeAttendanceDetail.interaction'
import updateAttendanceState from '../../../../../../@core/interaction/attendance/UpdateAttendance.interaction'
import deleteAttendance from '../../../../../../@core/interaction/attendance/DeleteAttendance.interaction';
import { employeeListToOptionsMapper } from '../../../../../../@core/mapper/employee/GetEmployeeList.mapper';
import DateService from '../../../../../../services/Date.service';

const dateService = new DateService();

export default class HrEmployeeAttendanceDetailImpl {

  getJobList(params: GetJobListDTO) {
    return getJobList(params).then((res: any) => jobListToObjById(res.data.content.job));
  }

  getEmployeeList(params: GetEmployeeListDTO): Promise<EmployeeOption[]> {
    return getEmployeeList(params).then((res: any) =>
      employeeListToOptionsMapper(res.data.content),
    );
  }

  getAttendance(params) {
    return getAttendanceDetail(params).then((res: any) =>
      {
        return res.data.content
      }
    );
  }
  submitAttendance(params) {
    return createAttendance(params);
  }
  updateAttendance(body) {
    updateAttendanceState(body);
  }
  deleteAttendance(params) {
    return deleteAttendance(params);
  }
  getGeofencing(params): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const URL = Constant.URL_BASE_ATTENDANCE + "/v3/absensi/detail/location"
      return API.get(URL, {params}).then(async (res: any) => {
        if (res.status === 200) {
          const content = res.data.content;
          resolve(content);
        } else {
          reject(res);
        }
      })
    });
  }
}
