import { UploadImageDTO, UploadImageResp } from '../../interface/asset/UploadImage.interface';
import Constant from '../../../config/Constants';
import API from '../../../services/API.service';

export default function uploadFile(imgFile: UploadImageDTO): Promise<UploadImageResp> {
  const URL = Constant.URL_BASE_MISC + '/v3/upload/template';
  const formData = new FormData();

  formData.append('file', imgFile);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return API.post(URL, formData, config);
}