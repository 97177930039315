import React from 'react';
import { Route, Switch } from 'react-router';
import AdminDashboard from '../../pages/dashboard/AdminDashboard';
import EmployeeDashboard from '../../pages/dashboard/personal-detail/EmployeeDashboard';
import NoAccess from '../../pages/error/NoAccess';
import NotFound from '../../pages/error/NotFound';
import HrLeaveCorrectionList from '../../pages/hr/leaves/leave-correction-list/HrLeaveCorrectionList';
import HrLeaveCorrectionAdd from '../../pages/hr/leaves/leave-correction-list/pages/add-leave-correction/HrAddLeaveCorrection';
import HrLeaveList from '../../pages/hr/leaves/HrLeaveList';
import HrMySubmissionLeave from '../../pages/hr/leaves/my-leave/pages/my-submission-leave/HrMySubmissionLeave';
import HrMySubmissionLeaveDetail from '../../pages/hr/leaves/my-leave/pages/my-submission-leave-detail/HrMySubmissionLeaveDetail';
import HrAddMySubmissionLeave from '../../pages/hr/leaves/my-leave/pages/add-my-submission-leave/HrAddMySubmissionLeave';
import HrEmployeeSubmissionLeave from '../../pages/hr/leaves/employee-leave/pages/employee-submission-leave/HrEmployeeSubmissionLeave';
import HrEmployeeSubmissionLeaveDetail from '../../pages/hr/leaves/employee-leave/pages/employee-submission-leave-detail/HrEmployeeSubmissionLeaveDetail';
import HrAddEmployeeSubmissionLeave from '../../pages/hr/leaves/employee-leave/pages/add-employee-submission-leave/HrAddEmployeeSubmissionLeave';
import HrEmployeeApprovalLeaveDetail from '../../pages/hr/leaves/employee-leave/pages/employee-approval-leave-detail/HrEmployeeApprovalLeaveDetail';
import HrConfigurationLeave from '../../pages/hr/leaves/configuration-leave/pages/configuration-leave/HrConfigurationLeave';
import HrAddConfigurationLeave from '../../pages/hr/leaves/configuration-leave/pages/add-configuration-leave/HrAddConfigurationLeave';
import HrConfigurationLeaveDetail from '../../pages/hr/leaves/configuration-leave/pages/configuration-leave-detail/HrConfigurationLeaveDetail';
import HrConfigurationLeaveUpdate from '../../pages/hr/leaves/configuration-leave/pages/configuration-leave-update/HrConfigurationLeaveUpdate';
import { Layout } from 'antd';
import AuthContentHeader from './AuthContentHeader';
import AuthSidebar from './auth-sidebar/AuthSidebar';
import HrEmployeeApprovalLeave from '../../pages/hr/leaves/employee-leave/pages/employee-approval-leave/HrEmployeeApprovalLeave';
import ImportMasterWorkingSchedule from '../../pages/hr/attendance/configuration/master-working-schedule/import-master-working-schedule/ImportMasterWorkingSchedule';
import ImportEmployeeWorkingSchedule from '../../pages/hr/attendance/configuration/employee-working-schedule/import-employee-working-schedule/ImportEmployeeWorkingSchedule';
import AddEmployeeWorkingSchedule from '../../pages/hr/attendance/configuration/employee-working-schedule/add-employee-working-schedule/AddEmployeeWorkingSchedule';
import ListEmployeeWorkingSchedule from '../../pages/hr/attendance/configuration/employee-working-schedule/list-employee-working-schedule/ListEmployeeWorkingSchedule';
import AddMasterWorkingSchedule from '../../pages/hr/attendance/configuration/master-working-schedule/add-master-working-schedule/AddMasterWorkingSchedule';
import ListMasterWorkingSchedule from '../../pages/hr/attendance/configuration/master-working-schedule/list-master-working-schedule/ListMasterWorkingSchedule';
import ListMasterHoliday from '../../pages/hr/attendance/configuration/master-holiday/list-master-holiday/ListMasterHoliday';
import AddMasterHoliday from '../../pages/hr/attendance/configuration/master-holiday/add-master-holiday/AddMasterHoliday';
import ListRecurringHolidays from '../../pages/hr/attendance/configuration/recurring-holidays/list-recurring-holidays/ListRecurringHolidays';
import AddRecurringHolidays from '../../pages/hr/attendance/configuration/recurring-holidays/add-recurring-holidays/AddRecurringHolidays';
import DetailRecurringHolidays from '../../pages/hr/attendance/configuration/recurring-holidays/detail-recurring-holidays/DetailRecurringHolidays';
import AddMassLeave from '../../pages/hr/leaves/mass-leave/add-mass-leave/AddMassLeave';
import ListMassLeave from '../../pages/hr/leaves/mass-leave/list-mass-leave/ListMassLeave';
import FirterLeaveUsageReport from '../../pages/hr/leaves/leave-usage-report/filter-leave-usage-report/FilterLeaveUsageReport';
import ListLeaveUsageReport from '../../pages/hr/leaves/leave-usage-report/list-leave-usage-report/ListLeaveUsageReport';
import HrRecapAttendanceList from '../../pages/hr/attendance/recap-attendance/pages/recap-attendance/HrRecapAttendanceList';
import HrMyRecapAttendanceList from '../../pages/hr/attendance/my-recap-attendance/pages/my-recap-attendance/HrMyRecapAttendanceList';
import HrCalendarRecapAttendance from '../../pages/hr/attendance/my-recap-attendance/pages/calendar-recap-attendance/HrCalendarRecapAttendance';
import HrEmployeeAttendanceList from '../../pages/hr/attendance/employee-attendance/pages/employee-attendance/HrEmployeeAttendanceList';
import HrAddEmployeeAttendance from '../../pages/hr/attendance/employee-attendance/pages/add-employee-attendance/HrAddEmployeeAttendance';
import HrEmployeeAttendanceDetail from '../../pages/hr/attendance/employee-attendance/pages/employee-attendance-detail/HrEmployeeAttendanceDetail';
import ImportLeaveCorrection from '../../pages/hr/leaves/import-leave-correction/ImportLeaveCorrection';
import HrMissingAttendance from '../../pages/hr/attendance/missing-attendance/pages/missing-attendance/HrMissingAttendance';
import HrEmployeeAttendanceUpdate from '../../pages/hr/attendance/employee-attendance/pages/employee-attendance-update/HrEmployeeAttendanceUpdate';
import HrAddOvertimeConfiguraton from '../../pages/hr/overtime/configuration/pages/company-overtime-configuration/HrAddCompanyOvtConfiguration';
import HrEmployeeOvertimeConfiguraton from '../../pages/hr/overtime/configuration/pages/employee-overtime-configuration/HrEmployeeOvtConfiguration';
import HrAddEmployeeOvertimeConfiguraton from '../../pages/hr/overtime/configuration/pages/add-employee-overtime-configuration/HrAddEmployeeOvtConfiguration';
import HrUpdateEmployeeOvertimeConfiguraton from '../../pages/hr/overtime/configuration/pages/update-employee-overtime-configuration/HrUpdateEmployeeOvtConfiguration';
import DetailMasterWorkingSchedule from '../../pages/hr/attendance/configuration/master-working-schedule/detail-master-working-schedule/DetailMasterWorkingSchedule';
import ListMySubmissionOvertime from '../../pages/hr/overtime/my-overtime/my-submission-overtime-list/MySubmissionOvertimeList';
import ListEmployeeSubmissionOvertime from '../../pages/hr/overtime/employee-overtime/employee-submission-overtime/employee-submission-overtime-list/EmployeeSubmissionOvertimeList';
import AddEmployeeSubmissionOvertime from '../../pages/hr/overtime/employee-overtime/employee-submission-overtime/add-employee-submission-overtime/AddEmployeeSubmissionOvertime';
import ListEmployeeApprovalOvertime from '../../pages/hr/overtime/employee-overtime/employee-approval-overtime/list-employee-approval-overtime/EmployeeApprovalOvertimeList';
import DetailEmployeeSubmissionOvertime from '../../pages/hr/overtime/employee-overtime/employee-submission-overtime/detail-employee-submission-overtime/DetailSubmissionOvertime';
import AddMySubmissionOvertime from '../../pages/hr/overtime/my-overtime/add-my-submission-overtime/AddMySubmissionOvertime';
import DetailMySubmissionOvertime from '../../pages/hr/overtime/my-overtime/detail-my-submission-ovetime/DetailMySubmissionOvertime';
import DetailEmployeeApprovalOvertime from '../../pages/hr/overtime/employee-overtime/employee-approval-overtime/detail-employee-approval-overtime/DetailEmployeeApprovalOvertime';
import HrEmployeeOvertimereport from '../../pages/hr/overtime/employee-overtime/pages/employee-overtime-report/HrEmployeeOvertimeReport';
import HrEmployeeOvertimereportFilter from '../../pages/hr/overtime/employee-overtime/pages/employee-overtime-filter-report/OvertimeReportFilter';
import DetailMassLeave from '../../pages/hr/leaves/mass-leave/detail-mass-leave/DetailMassLeave';
import MasterDepartmentList from '../../pages/hr/employee/master-department/master-department-list/MasterDepartmentList';
import AddMasterDepartment from '../../pages/hr/employee/master-department/add-master-department/AddMasterDepartment';
import DetailMasterDepartment from '../../pages/hr/employee/master-department/detail-master-department/DetailMasterDepartment';
import MasterRegionList from '../../pages/hr/employee/master-region/master-region-list/MasterRegionList';
import AddMasterRegion from '../../pages/hr/employee/master-region/add-master-region/AddMasterRegion';
import DetailMasterRegion from '../../pages/hr/employee/master-region/detail-master-region/DetailMasterRegion';
import MasterLocationList from '../../pages/hr/employee/master-location/list-master-location/MasterLocationList';
import AddMasterLocation from '../../pages/hr/employee/master-location/add-master-location/AddMasterLocation';
import DetailMasterLocation from '../../pages/hr/employee/master-location/detail-master-location/DetailMasterLocation';
import EmployeeTerminateList from '../../pages/hr/employee/employee-terminate/employee-terminate-list/EmployeeTerminateList';
import AddEmployeeTerminate from '../../pages/hr/employee/employee-terminate/add-employee-terminate/AddEmployeeTerminate';
import DetailEmployeeTerminate from '../../pages/hr/employee/employee-terminate/detail-employee-terminate/DetailEmployeeTerminate';
import ListMySubmissionClaim from '../../pages/hr/claim/my-submission-claim/list-my-submission-claim/ListMySubmissionClaim';
import AddMySubmissionClaim from '../../pages/hr/claim/my-submission-claim/add-my-submission-claim/AddMySubmissionClaim';
import DetailMySubmissionClaim from '../../pages/hr/claim/my-submission-claim/detail-my-submission-claim/DetailMySubmissionClaim';
import ListEmployeeSubmissionClaim from '../../pages/hr/claim/employee-submission-claim/list-employee-submission-claim/ListEmployeeSubmissionClaim';
import AddEmployeeSubmissionClaim from '../../pages/hr/claim/employee-submission-claim/add-employee-submission-claim/AddEmployeeSubmissionClaim';
import DetailEmployeeSubmissionClaim from '../../pages/hr/claim/employee-submission-claim/detail-employee-submission-claim/DetailEmployeeSubmissionClaim';
import ListEmployeeApprovalClaim from '../../pages/hr/claim/employee-approval-claim/list-employee-approval-claim/ListEmployeeApprovalClaim';
import DetailEmployeeApprovalClaim from '../../pages/hr/claim/employee-approval-claim/detail-employee-approval-claim/DetailEmployeeApprovalClaim';
import ListSubmissionLoan from '../../pages/hr/loan/submission-loan/list-submission-loan/ListSubmissionLoan';
import AddSubmissionLoan from '../../pages/hr/loan/submission-loan/add-submission-loan/AddSubmissionLoan';
import DetailSubmissionLoan from '../../pages/hr/loan/submission-loan/detail-submission-loan/DetailSubmissionLoan';
import HrMasterJobList from '../../pages/hr/employee/master-job/master-job-list/ListMasterJob';
import HrAddMasterJob from '../../pages/hr/employee/master-job/add-master-job/AddMasterJob';
import HrMasterJobDetail from '../../pages/hr/employee/master-job/master-job-detail/MasterJobDetail'
import HrMasterJobUpdate from '../../pages/hr/employee/master-job/master-job-update/MasterJobUpdate'
import HrEmployeeList from '../../pages/hr/employee/master-employee/employee-list/ListEmployee';
import HrAddEmployee from '../../pages/hr/employee/master-employee/employee-add/AddEmployee';
import HrUpdateEmployee from '../../pages/hr/employee/master-employee/employee-update/UpdateEmployee';
import HrDetailEmployee from '../../pages/hr/employee/master-employee/employee-detail/EmployeeDetail';
import HrUserList from '../../pages/hr/employee/users/user-list/ListUser';
import HrAddUser from '../../pages/hr/employee/users/user-add/AddUser';
import HrUpdateUser from '../../pages/hr/employee/users/user-update/UpdateUser';
import HrAccessList from '../../pages/hr/employee/access-rights/access-list/ListAccess';
import HrAddAccess from '../../pages/hr/employee/access-rights/access-add/AddAccess';
import HrUpdateAccess from '../../pages/hr/employee/access-rights/access-update/UpdateAccess';
import HrCompanyConfiguration from '../../pages/hr/company/configuration/company-configuration/CompanyConfiguration';
import HrClaimConfigurationList from '../../pages/hr/claim/configuration/list-claim-configuration/ListClaimConfiguration';
import HrAddClaimConfiguration from '../../pages/hr/claim/configuration/add-claim-configuration/AddClaimConfiguration';
import HrClaimPayment from '../../pages/hr/claim/payment/list-claim-payment/ListClaimPayment';
import HrEmployeeClaimreport from '../../pages/hr/claim/report/employee-claim-report/ClaimReport';
import HrEmployeeClaimreportFilter from '../../pages/hr/claim/report/employee-claim-report-filter/ClaimReportFilter';
import HrUpdateClaimConfiguration from '../../pages/hr/claim/configuration/update-claim-configuration/UpdateClaimConfiguration';
//loan
import HrLoanConfigurationList from '../../pages/hr/loan/configuration/list-loan-configuration/ListLoanConfiguration';
import HrAddLoanConfiguration from '../../pages/hr/loan/configuration/add-loan-configuration/AddLoanConfiguration';
import HrUpdateLoanConfiguration from '../../pages/hr/loan/configuration/update-loan-configuration/UpdateLoanConfiguration';
import ListApprovalLoan from '../../pages/hr/loan/approval-loan/list-approval-loan/ListApprovalLoan';
import DetailApprovalLoan from '../../pages/hr/loan/approval-loan/detail-approval-loan/DetailApprovalLoan';
import FilterLoanReport from '../../pages/hr/loan/loan-report/filter-loan-report/FilterLoanReport';
import ListLoanReport from '../../pages/hr/loan/loan-report/list-loan-report/ListLoanReport';
import HrLoanPayment from '../../pages/hr/loan/payment/list-loan-payment/ListLoanPayment';
import OrganizationStructure from '../../pages/hr/employee/organization-structure/OrganizationStructure';

import HrNewsList from '../../pages/hr/news/send-news/list-news/ListNews';
import AddNews from '../../pages/hr/news/send-news/add-news/AddNews';
import UpdateNews from '../../pages/hr/news/send-news/update-news/UpdateNews';
import FilterLoanInstallmentReport from '../../pages/hr/loan/loan-installment-report/filter-loan-installment-report/FilterLoanInstallmentReport';
import ListLoanInstallmentReport from '../../pages/hr/loan/loan-installment-report/list-loan-installment-report/ListLoanInstallmentReport';
import TerminationNotification from '../../pages/dashboard/notification/TerminationNotification';
import ScheduleWorkNotification from '../../pages/dashboard/notification/ScheduleWorkNotification';
import EmployeeNews from '../../pages/dashboard/news/EmployeeNews';
import EmployeeNewsDetail from '../../pages/dashboard/news/EmployeeNewsDetail';
import DashboardManager from '../../pages/dashboard/approval/DashboardManager';
import RecapRadius from '../../pages/hr/attendance/recap-radius/list-recap-radius/ListRecapRadius'

//superadmin
import CompanyApproval from '../../pages/admin/company-appoval/CompanyApproval';
import ListBanner from '../../pages/admin/banner/list-banner/ListBanner';
import UploadBanner from '../../pages/admin/banner/upload-banner/UploadBanner';
import DetailBanner from '../../pages/admin/banner/detail-banner/DetailBanner';
import RegistrationForm from '../../pages/admin/join-with-us/JoinWithUs';
import ThankYou from '../../pages/admin/thank-you/ThankYou';

const { Header, Content, Sider } = Layout;

function AuthContentLayout() {
  return (
    <Layout>
      <Header>
        <AuthContentHeader />
      </Header>
      <Layout>
        <Sider width={220}>
          <AuthSidebar />
        </Sider>
        <Content>
          <div className="inner-content">
          <Switch>
            <Route
                exact
                strict
                path="/app/admin/company-approval"
                component={CompanyApproval}
              />
            <Route exact path="/app/admin/banner-list" component={ListBanner} /> 
            <Route strict path="/app/admin/banner/new/" component={UploadBanner} /> 
            <Route strict path="/app/admin/banner/:bannerId" component={DetailBanner} />
            <Route exact path="/app/admin/company-registration" component={RegistrationForm} /> 
            <Route exact path="/app/admin/thank-you" component={ThankYou} />
            <Route path="/app/admin-dashboard" component={AdminDashboard} />
            <Route path="/app/employee-dashboard" component={EmployeeDashboard} />
            <Route path="/app/manager-dashboard" component={DashboardManager} />
            <Route path="/app/no-access" component={NoAccess} />
            <Route path="/app/hr-leave/patch" component={HrLeaveList} />
            <Route exact path="/app/hr-leave/correction" component={HrLeaveCorrectionList} />
            <Route exact path="/app/hr-leave/correction/new" component={HrLeaveCorrectionAdd} />
            <Route strict path="/app/hr-leave/correction/import/" component={ImportLeaveCorrection} />
            <Route exact path="/app/hr-leave/my-submission" component={HrMySubmissionLeave} />
            <Route
              exact
              strict
              path="/app/hr-leave/my-submission/:leaveId"
              component={HrMySubmissionLeaveDetail}
            />
            <Route
              exact
              strict
              path="/app/hr-leave/my-submission/new/"
              component={HrAddMySubmissionLeave}
            />

            <Route
              exact
              path="/app/hr-leave/employee-submission"
              component={HrEmployeeSubmissionLeave}
            />
            <Route
              exact
              strict
              path="/app/hr-leave/employee-submission/:leaveId"
              component={HrEmployeeSubmissionLeaveDetail}
            />
            <Route
              exact
              strict
              path="/app/hr-leave/employee-submission/new/"
              component={HrAddEmployeeSubmissionLeave}
            />

            <Route
              exact
              path="/app/hr-leave/employee-approval"
              component={HrEmployeeApprovalLeave}
            />
            <Route
              exact
              strict
              path="/app/hr-leave/employee-approval/:leaveId"
              component={HrEmployeeApprovalLeaveDetail}
            />
            <Route
              exact
              strict
              path="/app/hr-leave/leave-configuration"
              component={HrConfigurationLeave}
            />
            <Route
              exact
              strict
              path="/app/hr-leave/leave-configuration/new/"
              component={HrAddConfigurationLeave}
            />
            <Route
              exact
              strict
              path="/app/hr-leave/leave-configuration/:leaveId"
              component={HrConfigurationLeaveDetail}
            />
            <Route
              exact
              strict
              path="/app/hr-leave/leave-configuration/update/:leaveId"
              component={HrConfigurationLeaveUpdate}
            />

            <Route 
              exact
              path="/app/hr-leave/leave-usage-report"
              component={ListLeaveUsageReport}
            />

            <Route 
              exact
              strict
              path="/app/hr-leave/leave-usage-report/filter/"
              component={FirterLeaveUsageReport}
            />

            

            <Route
              exact
              strict
              path="/app/attendance/master-working-schedule/import/"
              component={ImportMasterWorkingSchedule}
            />

            <Route
              exact
              strict
              path="/app/attendance/master-working-schedule/new/"
              component={AddMasterWorkingSchedule}            
            />

            <Route
              exact
              strict
              path="/app/attendance/master-working-schedule/:masterId"
              component={DetailMasterWorkingSchedule}            
            />    

            <Route
              exact
              path="/app/attendance/master-working-schedule"
              component={ListMasterWorkingSchedule}            
            />
            <Route
              exact
              strict
              path="/app/attendance/employee-working-schedule/import/"
              component={ImportEmployeeWorkingSchedule}
            />

            <Route
              exact
              strict
              path="/app/attendance/employee-working-schedule/new/"
              component={AddEmployeeWorkingSchedule}
            />
            
            <Route
              exact
              strict
              path="/app/attendance/employee-working-schedule"
              component={ListEmployeeWorkingSchedule}
            />

            <Route
              exact
              strict
              path="/app/attendance/master-holiday"
              component={ListMasterHoliday}
            />

            <Route
              exact
              strict
              path="/app/attendance/master-holiday/new/"
              component={AddMasterHoliday}
            />

            <Route
              exact
              strict
              path="/app/attendance/master-holiday/:mtrHolidayId"
              component={AddMasterHoliday}
            />

            <Route
              exact
              strict
              path="/app/attendance/recurring-holidays"
              component={ListRecurringHolidays}
            />

            <Route
              exact
              strict
              path="/app/attendance/recurring-holidays/new/"
              component={AddRecurringHolidays}
            />

            <Route
              exact
              strict
              path="/app/attendance/recurring-holidays/:rcrHolidayId"
              component={DetailRecurringHolidays}
            />

            <Route 
              exact
              path="/app/hr-leave/all-request/new/"
              component={AddMassLeave}
            />

            <Route 
              exact
              strict
              path="/app/hr-leave/all-request/:leaveAllId"
              component={DetailMassLeave}
            />

            <Route 
              exact
              strict
              path="/app/hr-leave/all-request"
              component={ListMassLeave}
            />

            <Route
              exact
              path="/app/hr-attendance/recap-attendance"
              component={HrRecapAttendanceList}
            />

            <Route
              exact
              path="/app/hr-attendance/my-recap-attendance"
              component={HrMyRecapAttendanceList}
            />

            <Route
              exact
              path="/app/hr-attendance/calendar-recap-attendance"
              component={HrCalendarRecapAttendance}
            />

            <Route
              exact
              path="/app/hr-attendance/employee-attendance"
              component={HrEmployeeAttendanceList}
            />
            
            <Route
              exact
              strict
              path="/app/hr-attendance/employee-attendance/new/"
              component={HrAddEmployeeAttendance}
            />
            <Route
              exact
              strict
              path="/app/hr-attendance/employee-attendance/:attendanceId"
              component={HrEmployeeAttendanceDetail}
            />
            <Route
              exact
              strict
              path="/app/hr-attendance/employee-attendance/update/:attendanceId"
              component={HrEmployeeAttendanceUpdate}
            />
            <Route
              exact
              strict
              path="/app/hr-overtime/company-overtime-configuration/"
              component={HrAddOvertimeConfiguraton}
            />

            <Route
              strict
              path="/app/hr-attendance/check-missing-attendance"
              component={HrMissingAttendance}
            />
            <Route 
              exact
              strict
              path="/app/hr-overtime/my-submission-overtime"
              component={ListMySubmissionOvertime}
            />
            <Route
              strict
              path="/app/hr-employee/list-master-job"
              component={HrMasterJobList}
            />
            <Route
              strict
              path="/app/hr-employee/master-job/new/"
              component={HrAddMasterJob}
            />
            <Route
              strict
              path="/app/hr-employee/detail-master-job/:jobId"
              component={HrMasterJobDetail}
            />
            <Route
              strict
              path="/app/hr-employee/update-master-job/:jobId"
              component={HrMasterJobUpdate}
            />
            <Route
              strict
              path="/app/hr-employee/list-employee"
              component={HrEmployeeList}
            />
            <Route
              strict
              path="/app/hr-employee/master-employee/new/"
              component={HrAddEmployee}
            />
            <Route
              strict
              path="/app/hr-employee/update-master-employee/:employeeId"
              component={HrUpdateEmployee}
            />
            <Route
              strict
              path="/app/hr-employee/detail-employee/:employeeId"
              component={HrDetailEmployee}
            />
            <Route
              strict
              path="/app/hr-employee/list-user"
              component={HrUserList}
            />
            <Route
              strict
              path="/app/hr-employee/user/new/"
              component={HrAddUser}
            />
            <Route
              strict
              path="/app/hr-employee/user/update-user/:userId"
              component={HrUpdateUser}
            />
            <Route
              strict
              path="/app/hr-employee/list-access"
              component={HrAccessList}
            />
            <Route
              strict
              path="/app/hr-employee/access/new"
              component={HrAddAccess}
            />
            <Route
              strict
              path="/app/hr-employee/update-access/:accessId"
              component={HrUpdateAccess}
            />
            <Route
              exact
              strict
              path="/app/hr-overtime/my-submission-overtime/new/"
              component={AddMySubmissionOvertime}
            />
            
            <Route
              exact
              strict
              path="/app/hr-overtime/employee-overtime-configuration/update/:overtimeId"
              component={HrUpdateEmployeeOvertimeConfiguraton}
            />
            <Route
              exact
              strict
              path="/app/hr-company/company-configuration/"
              component={HrCompanyConfiguration}
            />
            <Route
              exact
              strict
              path="/app/hr-claim/configuration/list-configuration"
              component={HrClaimConfigurationList}
            />
            <Route
              exact
              strict
              path="/app/hr-overtime/employee-overtime-configuration/"
              component={HrEmployeeOvertimeConfiguraton}
              
            />
            <Route
              strict
              exact
              path="/app/hr-claim/configuration/new/"
              component={HrAddClaimConfiguration}
            />

            <Route
              strict
              exact
              path="/app/hr-claim/update-claim-configuration/:claimId"
              component={HrUpdateClaimConfiguration}
            />

            <Route
              exact
              strict
              path="/app/hr-overtime/my-submission-overtime/:submissionId"
              component={DetailMySubmissionOvertime}
            />

            <Route 
              exact
              strict
              path="/app/hr-overtime/employee-submission-overtime"
              component={ListEmployeeSubmissionOvertime}
            />

            <Route 
              exact
              strict
              path="/app/hr-overtime/employee-submission-overtime/new/"
              component={AddEmployeeSubmissionOvertime}
            />

            <Route 
              exact
              strict
              path="/app/hr-overtime/employee-submission-overtime/:submissionId"
              component={DetailEmployeeSubmissionOvertime}
            />

            <Route 
              exact
              strict
              path="/app/hr-overtime/employee-approval-overtime"
              component={ListEmployeeApprovalOvertime}
            />

            <Route 
              exact
              strict
              path="/app/hr-overtime/employee-approval-overtime/:overtimeId"
              component={DetailEmployeeApprovalOvertime}
            />
            
            <Route 
              exact
              strict
              path="/app/hr-overtime/employee-overtime-configuration/new/"
              component={HrAddEmployeeOvertimeConfiguraton}
            />
            <Route
              exact
              strict
              path="/app/hr-claim/claim-payment/"
              component={HrClaimPayment}
            />
            <Route
              exact
              strict
              path="/app/hr-claim/employee-claim-report/"
              component={HrEmployeeClaimreport}
            />
            <Route
              exact
              strict
              path="/app/hr-overtime/employee-overtime-report/"
              component={HrEmployeeOvertimereport}
            />
            <Route
              exact
              strict
              path="/app/hr-overtime/employee-overtime-report-filter/"
              component={HrEmployeeOvertimereportFilter}
            />

            <Route 
              strict
              exact
              path="/app/hr-employee/master-department"
              component={MasterDepartmentList}
            />

            <Route 
              strict
              exact
              path="/app/hr-employee/master-department/new/"
              component={AddMasterDepartment}
            />

            <Route 
              strict
              exact
              path="/app/hr-employee/master-department/:departmentId"
              component={DetailMasterDepartment}
            />

            <Route
              exact
              strict
              path="/app/hr-employee/master-region"
              component={MasterRegionList}
              
            />
            <Route 
              strict
              exact
              path="/app/hr-claim/employee-claim-report-filter/"
              component={HrEmployeeClaimreportFilter}
            />
            <Route
              strict
              path="/app/hr-loan/configuration/list-configuration"
              component={HrLoanConfigurationList}
            />
            <Route
              exact
              strict
              path="/app/hr-employee/master-region/new/"
              component={AddMasterRegion}
            />
            <Route
              exact
              strict
              path="/app/hr-employee/master-region/:regionId"
              component={DetailMasterRegion}
            />

            <Route
              exact
              strict
              path="/app/hr-employee/master-location"
              component={MasterLocationList}
            />
            <Route
              exact
              strict
              path="/app/hr-employee/master-location/new/"
              component={AddMasterLocation}
            />
            <Route
              exact
              strict
              path="/app/hr-employee/master-location/:locationId"
              component={DetailMasterLocation}
            />

            <Route 
              exact
              strict
              path="/app/hr-employee/employee-terminate"
              component={EmployeeTerminateList}
            />
            <Route 
              exact
              strict
              path="/app/hr-employee/employee-terminate/new/"
              component={AddEmployeeTerminate}
            />
            <Route 
              exact
              strict
              path="/app/hr-employee/employee-terminate/:terminateId"
              component={DetailEmployeeTerminate}
            />

            <Route 
              exact
              strict
              path="/app/hr-claim/my-submission-claim"
              component={ListMySubmissionClaim}
            />
            <Route 
              exact
              strict
              path="/app/hr-claim/my-submission-claim/new/"
              component={AddMySubmissionClaim}
            />
            <Route 
              exact
              strict
              path="/app/hr-claim/my-submission-claim/:claimId"
              component={DetailMySubmissionClaim}
            />

            <Route 
              exact
              strict
              path="/app/hr-claim/employee-submission-claim"
              component={ListEmployeeSubmissionClaim}
            />
            <Route 
              exact
              strict
              path="/app/hr-claim/employee-submission-claim/new/"
              component={AddEmployeeSubmissionClaim}
            />
            <Route 
              exact
              strict
              path="/app/hr-claim/employee-submission-claim/:claimId"
              component={DetailEmployeeSubmissionClaim}
            />
            
            <Route 
              exact
              strict
              path="/app/hr-claim/employee-approval-claim"
              component={ListEmployeeApprovalClaim}
            />
            <Route 
              exact
              strict
              path="/app/hr-claim/employee-approval-claim/:claimId"
              component={DetailEmployeeApprovalClaim}
            />
            
            <Route 
              exact
              strict
              path="/app/hr-loan/submission-loan"
              component={ListSubmissionLoan}
            />
            <Route
              exact
              strict
              path="/app/hr-loan/submission-loan/new/"
              component={AddSubmissionLoan}
            />
            <Route 
              exact
              strict
              path="/app/hr-loan/submission-loan/:loanId"
              component={DetailSubmissionLoan}
              
            />
            <Route
              exact
              strict
              path="/app/hr-loan/configuration/new/"
              component={HrAddLoanConfiguration}
            />
            <Route
              strict
              path="/app/hr-loan/update-loan-configuration/:loanId"
              component={HrUpdateLoanConfiguration}
            />

            <Route 
              exact
              strict
              path="/app/hr-loan/approval-loan"
              component={ListApprovalLoan}
            />
            <Route 
              exact
              strict
              path="/app/hr-loan/approval-loan/:loanId"
              component={DetailApprovalLoan}
            />

            <Route 
              exact
              strict
              path="/app/hr-loan/loan-report/"
              component={ListLoanReport}
            />
            <Route 
              exact
              strict
              path="/app/hr-loan/loan-report/filter/"
              component={FilterLoanReport}
            />

            <Route
              exact
              strict
              path="/app/hr-loan/loan-payment/"
              component={HrLoanPayment}
            />

            <Route
              exact
              strict
              path="/app/hr-news/news-list"
              component={HrNewsList}
            />

            <Route
              exact
              strict
              path="/app/hr-news/new/"
              component={AddNews}
            />
            <Route
              exact
              strict
              path="/app/hr-news/update-news/:newsId"
              component={UpdateNews}
              
            />
            <Route
              exact
              strict
              path="/app/hr-employee/structure-organization"
              component={OrganizationStructure}
            />
            
            <Route
              exact
              path="/app/hr-loan/loan-installment-report/filter"
              component={FilterLoanInstallmentReport}
            />
            <Route
              exact
              strict
              path="/app/hr-loan/loan-installment-report/"
              component={ListLoanInstallmentReport}
            />

            <Route 
              exact
              strict
              path="/app/notification/employee-termination"
              component={TerminationNotification}
            />
            <Route 
              exact
              strict
              path="/app/notification/employee-schedule-work"
              component={ScheduleWorkNotification}
            />
            <Route 
              exact
              strict
              path="/app/employee/list-news"
              component={EmployeeNews}
            />
            <Route 
              exact
              strict
              path="/app/employee/detail-news/:newsId"
              component={EmployeeNewsDetail}
            />
            <Route 
              exact
              strict
              path="/app/hr-attendance/recap-radius"
              component={RecapRadius}
            />
            <Route path="*" component={NotFound} />
            </Switch>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default AuthContentLayout;
