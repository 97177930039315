import { useEffect, useState } from 'react';
import ToastService from '../../../../../services/Toast.service';
import { ToolbarDetailActionButton } from '../../../employee/components/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface';
import AddSubmissionLoanTemplate from '../template/AddSubmissionLoanTemplate';
import AddSubmissionLoanUsecase from './DetailSubmissionLoan.usecase';
import {
  DetailInstallmentType,
  DetailSubmisiionLoanType,
} from '../template/AddSubmissionLoanTemplate.interface';
import { GetDetailInstallment, GetDetailSubmissionLoan } from '../../../../../@core/mapper/loan/AddSubmissionLoan.mapper';

const usecase = new AddSubmissionLoanUsecase();
const toastService = new ToastService();
const steps = [
  "Pengajuan", "Disetujui",
  "Persetujuan Ke-2", "Ditolak",
  "Batal", "Selesai", "Dibayar"
]
let historyId;

export default function DetailSubmissionLoan({ match }) {
  const loanId = match.params.loanId
  const [isLoading, setIsLoading] = useState(false);
  const [detailDataSource, setDetailDataSource] = useState<DetailSubmisiionLoanType>();
  const [detailInstallment, setDetailInstallment] = useState<DetailInstallmentType[]>();

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Pengajuan Pinjaman',
        href: '/v2/app/hr-loan/submission-loan',
      },
      {
        clickable: false,
        displayPathName: detailDataSource?.name,
        href: '/v2/app/hr-loan/submission-loan/new/',
      },
    ],
  };

  const submitButton = { active: false, disabled: false };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: false,
    onClick: () => {
      const back = window.confirm('Anda Ingin Kembali');
      if (back) {
        window.location.href = '/v2/app/hr-loan/submission-loan';
      }
    },
  };

  useEffect(() => {
    init();
  }, []);

  function init() {
    setIsLoading(true);
    usecase.getDetail(loanId).then((res) => {
      const { loan_installment, loan_request, approval1, approval2 } = res.data.content;

      usecase.getConfigLoan().then((cfg) => {
        const resultDetail = GetDetailSubmissionLoan(loan_request, cfg, approval1, approval2);
        const resultInstall = GetDetailInstallment(loan_installment);
  
        historyId = loan_request.history_id;
  
        setDetailDataSource(resultDetail);
        setDetailInstallment(resultInstall);
  
        setIsLoading(false);
      })
    }).catch(e => {
      toastService.error(e)

      setIsLoading(false);
    });
  }

  const onFieldChange = (e, form) => {};

  const onFinish = (e) => {};

  return (
    <div className="detail-submission-loan">
      <AddSubmissionLoanTemplate
        key="detail-submission-loan"
        breadcrumbConf={breadcrumbConf}
        firstActionButton={firstActionButton}
        secondActionButton={{ active: false }}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        dropdownActionButton={{ visible: false }}
        submitButton={submitButton}
        steps={steps}
        activeSteps={detailDataSource?.state}
        onFieldChange={onFieldChange}
        onFinish={onFinish}
        detailDataSource={detailDataSource}
        detailInstallment={detailInstallment}
        onFinishFailed={null}
        viewMode={true}
        firstApprovalData={detailDataSource?.approval1}
        secondApprovalData={detailDataSource?.approval2}
        historyId={historyId}
        isLoading={isLoading}
      />
    </div>
  );
}
