import { useEffect, useState } from 'react';
import { ToolbarDetailActionButton } from '../../components/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface';
import AddEmployeeTerminateTemplate from '../termplate/AddEmployeeTerminateTemplate';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import ToastService from '../../../../../services/Toast.service';
import { DetailEmployeeTerminateType } from '../termplate/AddEmployeeTerminateTemplate.interface';
import DetailEmployeeTerminateUsecase from './DetailEmployeeTerminate.usecase';
import { GetJobListDTO } from '../../../../../@core/interface/job/GetJobList.interface';
import { getEmployeeTerminateDetail } from '../../../../../@core/mapper/employee/EmployeeTerminate.mapper';

const usecase = new DetailEmployeeTerminateUsecase();
const localNotificationService = new LocalNotificationService();
const toastService = new ToastService();

let historyId;
let jobList;

export default function DetailEmployeeTerminate({ match }) {
  const terminateId = match.params.terminateId

  const [isLoading, setIsLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [detailDataSource, setDetailDataSource] = useState<DetailEmployeeTerminateType>({
    termination_code: "",
    department: "",
    effective_date: "",
    employee: "",
    information: "",
    job: "",
    join_date: "",
    leave_remaining: null,
    must_be_returned: "",
    terminate_category: "",
    terminate_reason: "",
    is_active: false,
    insert_file_table: null,
  });

  useEffect(() => {
    init();
  }, []);

  async function init() {
    setIsLoading(true);

    const empList = await usecase.getEmployeeList({
      limit: 500,
      sort: 'name_related|asc',
    });

    setEmployeeList(empList);

    usecase.getCategory().then((res) => setCategoryList(res));

    usecase.getDetail(terminateId).then((res) => {
      const content = res.data.content;
      setDetailDataSource(getEmployeeTerminateDetail(content));
    });

    const params: GetJobListDTO = {
      limit: 300,
      sort: 'id|asc',
    };

    jobList = await usecase.getJobList(params);
    setIsLoading(false);
  }

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Terminasi Karyawan',
        href: '/v2/app/hr-employee/employee-terminate',
      },
      {
        clickable: false,
        displayPathName: detailDataSource.termination_code,
        href: '/v2/app/hr-employee/employee-terminate/new/',
      },
    ],
  };

  const submitButton = { active: false, disabled: false };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: false,
    onClick: () => {
      const back = window.confirm('Anda Ingin Kembali');
      if (back) {
        window.location.href = '/v2/app/hr-employee/employee-terminate';
      }
    },
  };

  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      {
        key: 'inactive',
        disabled: !detailDataSource.is_active,
        label: 'Non-Aktifkan',
        onClick: () => setActive(),
      },
    ],
  };

  function setActive() {
    setIsLoading(true);

    const body = { id: [terminateId] }

    usecase.active(body).then(
      (res: any) => {
        localNotificationService.openSuccess("Berhasil Menonaktifkan Data Terminasi", '', 1);
        setTimeout(() => {
          window.location.href = "/v2/app/hr-employee/employee-terminate"
        }, 1000)
      },
      (err) => {
        setIsLoading(false);
        toastService.error(err);
      },
    );
  }

  const onFinish = (e) => {
  };

  return (
    <div className="detail-employee-terminate">
      <AddEmployeeTerminateTemplate
        breadcrumbConf={breadcrumbConf}
        firstActionButton={firstActionButton}
        secondActionButton={{ active: false }}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        dropdownActionButton={dropdownButton}
        detailDataSource={detailDataSource}
        submitButton={submitButton}
        employeeList={employeeList}
        jobList={jobList}
        categoryList={categoryList}
        isLoading={isLoading}
        historyId={historyId}
        viewMode={true}
        onFinish={onFinish}
      />
    </div>
  );
}
