import { useHistory, useLocation } from "react-router-dom";
import ExpandableListTemplate from "../../../../../templates/expandable-list-template/ExpandableListTemplate";
import { useEffect, useState, useLayoutEffect } from "react";
import { ExpandableListTemplatePagination } from "../../../../../templates/expandable-list-template/ExpandableListTemplate.interface";
import { BreadcrumbConf } from "../../../../../components/breadcrumb/Breadcrumb.interface";
import { createExcelFromByte } from "../../../payroll/configuration/master-payroll/list-master-payroll/ListMasterPayroll.service";
import { ListLoanReportConstant } from "./ListLoanReport.constant";
import ListLoanReportUseCase from "./ListLoanReport.usecase";
import { GetFilterLoanMapper } from "../../../../../@core/mapper/loan/FilterLoan.mapper";

const constant = ListLoanReportConstant
const usecase = new ListLoanReportUseCase()

let page = 1;
let loadPagination = true;
let employee_id
let config_id
let installment_outstanding
let job_id
let sort
let selectionId
const limit = 10;

export default function ListLoanReport() {
  const history = useHistory();
  const location:any = useLocation();

  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([]);
  const [tableScroll] = useState({ y: 900, x: 'max-content' });
  const [disableExportButton, setDisableExportButton] = useState(false);
  const [visibleSubmissionModal, setVisibleSubmissionModal] = useState(false)
  const [paramsSubmissionModal, setParamsSubmissionModal] = useState({ name: null, nik: null, params: null})
  const [breadcrumbConf, setBreadcrumbConf] = useState<BreadcrumbConf>({
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Laporan Pinjaman',
        href: "/v2/app/hr-loan/loan-report/filter/",
      },
    ],
  })
  const [pagination, setPagination] = useState<ExpandableListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });

  let tableHeader: any = constant.TABLE_HEADER
  let searchColumnKey = constant.SEARCH_COLUMN_KEY
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };

  useEffect(() => {
    tableHeader[6].render = t => t?.toLocaleString()
    tableHeader[7].render = t => t?.toLocaleString()
    tableHeader[8].render = t => t?.toLocaleString()

    initialPage()
  }, [])

  useLayoutEffect(() => {
    return () => {
      page = 1;
    }
  }, [])

  function initialPage() {
    setIsLoading(true);
    const tempFilter:any = location.state?.data;
    
    if(tempFilter) {
      employee_id = tempFilter?.employee_id;
      config_id = tempFilter?.config_id;
      job_id = tempFilter?.job_id;
      installment_outstanding = tempFilter?.installment_outstanding;
    }
    const body = {
      page,
      limit,
      sort,
      employee_id,
      config_id,
      job_id,
      installment_outstanding,
      with_meta: true,
    };
    
    proccessFilterData({ employee_id, config_id, job_id, installment_outstanding })

    usecase.getAll(body).then((res: any) => {
      const content = res.data.content;
      const metadata = res.data.metadata;

      const resultDataSource = content ? GetFilterLoanMapper(content) : constant.DEFAULT_EMPTY_DATA_SOURCE
      setDataSource([...resultDataSource]);
      updatePaginationState(metadata?.total_records);

      loadPagination = true
      setDisableExportButton(!content)
      setIsLoading(false)
    })
  }

  const dropdownButton = {
    visible: true,
    disabled: isLoading || disableExportButton,
    options: [
      { key: 'export', label: 'Ekspor', onClick: () => exportData() },
    ]
  };

  function exportData() {
    setIsLoading(true)
    const params = {
      sort,
      ids: selectionId,
      employee_id,
      config_id,
      job_id,
      installment_outstanding,
      with_meta: true,
    }

    usecase.export(params).then((resp: any) => {
      setIsLoading(false);
      const content = resp.data.content;
      if (resp.status === 200) {
        const bytes = content.data
        const fileName = content.filename
        createExcelFromByte(bytes, fileName);
      }
    })
  }

  async function getLeavePlafon(record) {
    const leave_config_id = record.leave_type_id
    const employee_id = record.employee_id
    const nik = record.nik
    const name = record.employee_name
    const params = { leave_config_id, employee_id }

    setParamsSubmissionModal({ name, nik, params })
    setVisibleSubmissionModal(true)
  }

  function actionHideModal() {
    setVisibleSubmissionModal(false);
    setParamsSubmissionModal({ name: null, nik: null, params: null})
  }

  function proccessFilterData(filterData) {
    const manual_data = []
    if (!filterData.job_id) {
      manual_data.push(manualData('Semua Jabatan'))
    } else {
      manual_data.push(manualData('Jabatan Tertentu'))
    }

    if (!filterData.employee_id) {
      manual_data.push(manualData('Semua Karyawan'))
    } else {
      manual_data.push(manualData('Karyawan Tertentu'))
    }

    if (!filterData.config_id) {
      manual_data.push(manualData('Semua Tipe Pinjaman'))
    } else {
      manual_data.push(manualData('Tipe Pinjaman Tertentu'))
    }
    
    if (manual_data.length && breadcrumbConf.manualData.length <= 1) {
      const copyBreadcrumb = JSON.parse(JSON.stringify(breadcrumbConf))
      copyBreadcrumb.manualData.push(...manual_data)
      setBreadcrumbConf(copyBreadcrumb)
    }
  }

  function manualData(displayPathName: string) {
    return { clickable: false, displayPathName, href: null }
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      initialPage();
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionId) {
      selectionId = outputSelection;
    }
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    processPagination(outputPagination);
    processSelection(output.selection);
  };

  return (
    <div className="list-leave-usage-report">
      {/* <ModalSubmissionLeave
        actionHideModal={actionHideModal}
        visibleModal={visibleSubmissionModal}
        dataSource={paramsSubmissionModal}
      /> */}
      <ExpandableListTemplate 
        breadcrumbConf={breadcrumbConf}
        addButton={{ visible: false }}
        deleteButton={{ visible: false }}
        changeModeButton={{ visible: false }}
        exportButton={{ visible: false }}
        dropdownButton={dropdownButton}
        tableData={tableData}
        tableHeader={tableHeader}
        tableScroll={tableScroll}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        expandedRowRender={null}
        isLoading={isLoading}
        onRow={null}
      />
    </div>
  )
}