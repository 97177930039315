import UploadImage from '../../../../../components/upload-image/UploadImage';
import './UploadField.less';

export const UploadField = ({ onChange, value, viewMode }) => {
  function onChangeArchiveImg(output, procedure) {
    onChange?.({ file: output.file });
  }

  return (
    <div className="upload-field-banner">
      <UploadImage
        onChange={onChangeArchiveImg}
        componentId={'archive_img'}
        selectedImg={value?.img}
        canEdit={!viewMode}
      />
    </div>
  );
};
