import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Radio, Space, InputNumber, Select, Spin } from 'antd';
import FormBuilder from 'antd-form-builder';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import { useHistory } from 'react-router-dom';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import AddLoanConfiguration from './AddLoanConfiguration.usecase';


const useCaseImpl = new AddLoanConfiguration();
const localNotificationService = new LocalNotificationService();

export default function HrAddConfigurationLoan() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [, updateState] = React.useState<any>(); const forceUpdate = React.useCallback(() => updateState({}), []);
  const [employeeList, setEmployeeList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [monthJoin, setMonthJoin] = useState<any>();
  let [isLoading, setIsLoading] = useState(true);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Konfigurasi Pinjaman',
        href: "/v2/app/hr-loan/configuration/list-configuration",
      },
      {
        clickable: false,
        displayPathName: 'Baru',
      }
    ],
  };

  const ActiveWhen = ({ value, onChange }) => {
    return (
      <Radio.Group onChange={onChange} value={value}>
        <Space direction="vertical">
          <Radio value={'mulai_bergabung'}>Mulai Bergabung</Radio>
          <Radio
            value={'bergabung_dibulan'}>Bergabung di Bulan ke
            {value === 'bergabung_dibulan' ? <InputNumber defaultValue={monthJoin} onChange={(e) => setMonthJoin(e)} style={{ width: 50, marginLeft: 10 }} /> : null}
          </Radio>
        </Space>
      </Radio.Group>
    )
  }

  const meta: any = {
    columns: 1,
    fields: [
      {
        key: 'name',
        label: 'Nama Pinjaman',
        rules: [{ required: true, whitespace: true, message: "Nama Pinjaman harus diisi", }],
      },
      {
        key: 'max_plafon',
        label: 'Maksimum Plafon',
        required: true,
        message: 'Maksimum Plafon harus diisi',
        widget: 'number',
        widgetProps: {
          style:{ width: '50%' },
          formatter: value => `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ','),
        },
      },
      {
        key: 'interest_type',
        label: 'Metode Bunga',
        widget: 'select',
        required: true,
        message: 'Metode Bunga harus diisi',
        options: [
          { value: 'tanpa_bunga', label: 'Tanpa Bunga' },
          { value: 'flat', label: 'Flat' },
          { value: 'anuitas', label: 'Anuitas' },
        ],
      },
      {
        key: 'interest',
        label: '% Bunga',
        required: true,
        widget: 'number',
        message: 'Bunga harus diisi',
        widgetProps: {
          min: 1
        }
      },
      {
        key: 'max_tenor',
        label: 'Maksimum Tenor(Bulan)',
        required: true,
        widget: 'number',
        message: 'Maksimum Tenor harus diisi',
        widgetProps: {
          min: 1
        }
      },
      {
        key: 'active_when',
        label: 'Aktif Ketika',
        forwardRef: true,
        widget: ActiveWhen,
        options: [
          { value: 'mulai_bergabung', label: 'Mulai Bergabung' },
          { value: 'bergabung_dibulan', label: 'Bergabung di Bulan ke' },
        ],
        initialValue: 'mulai_bergabung',
      },
      {
        key: 'config_type',
        label: 'Berlaku Untuk',
        widget: 'radio-group',
        forwardRef: true,
        options: [
          { value: 'job', label: 'Jabatan' },
          { value: 'employee', label: 'Karyawan' },
        ],
        initialValue: 'job'
      },
      {
        key: 'job',
        label: 'Jabatan',
        required: true,
        message: 'Jabatan harus diisi',
        widget: 'select',
        options: jobList,
        widgetProps: {
          mode: 'multiple'
        },
      },
      {
        key: 'approval_1',
        label: 'Persetujuan 1',
        required: true,
        message: 'Persetujuan 1 harus diisi',
        widget: 'select',
        options: [
          { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
          { value: 'atasan_langsung', label: 'Atasan Langsung' },
          { value: 'jabatan', label: 'Jabatan' },
        ],
      },
    ],
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    const getJob = await useCaseImpl.getJob();
    const employeeList = await useCaseImpl.getEmpl();
    setJobList(getJob)
    setEmployeeList(employeeList);
    setIsLoading(false);
  }



  async function onFinish(e) {
    setIsLoading(true);
    const list_job = e.job && e.job.map(item => JSON.parse(item)).map(function (row) {
      return {
        job_id: row.id,
        name: row.job_name,
        dept_name: row.dept_name,
        dept_id: row.dept_id

      }
    })
    const list_employee = e.employee && e.employee.map(item => JSON.parse(item)).map(function (row) {
      return {
        employee_id: row.id,
        nik: row.nik,
        name: row.name,
        alternative_name: row.alternative_name,
        job_id: row.job_id,
        job_name: row.job_name,
        dept_id: row.dept_id,
        dept_name: row.dept_name
      }
    })
    let tempParam: any = {}
    e.job_approval1 && (tempParam['job_approval_1'] = JSON.parse(e.job_approval1)?.id)
    e.job_approval2 && (tempParam['job_approval_2'] = JSON.parse(e.job_approval2)?.id)
    e.employee && (tempParam['list_emp'] = list_employee)
    e.job && (tempParam['list_job'] = list_job)

    const params = {
      name: e.name.trim(),
      loan_rule: e.interest_type,
      loan_plafon: e?.max_plafon?.toString(),
      loan_interest: e?.interest?.toString(),
      max_installment: e?.max_tenor?.toString(),
      config_type: e.config_type,
      active_when: e.active_when,
      month_join: monthJoin ? monthJoin.toString() : '0',
      approval_1: e.approval_1,
      approval_2: e.approval_2,
      is_2nd_appr: (e.approval_2 && e.approval_2 !== 'tanpa_persetujuan') ? true : false,
      ...tempParam,
    }
    
    useCaseImpl.submitLoanConfiguration(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          history.push('/app/hr-loan/configuration/list-configuration');
          localNotificationService.openSuccess('Konfigurasi Pinjaman Berhasil Dibuat', '', 3);
        }

        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  let newMeta = meta;
  if (form.getFieldValue('interest_type')) {
    if (form.getFieldValue('interest_type') === 'tanpa_bunga') {
      const objIndex = newMeta.fields.findIndex((obj => obj.key === 'interest'));
      newMeta.fields[objIndex] = {
        key: 'hide_plafon_type',
        render: () => {
          return null
        }
      }
    }
  }

  if (form.getFieldValue('config_type')) {
    if (form.getFieldValue('config_type') === 'employee') {
      const objIndex = newMeta.fields.findIndex((obj => obj.key === 'job'));
      newMeta.fields[objIndex] = {
        key: 'employee',
        label: 'Karyawan',
        required: true,
        message: 'Karyawan harus diisi',
        widget: 'select',
        options: employeeList,
        widgetProps: {
          mode: 'multiple'
        },
      }
    } else if (form.getFieldValue('config_type') === 'job') {
      const objIndex = newMeta.fields.findIndex((obj => obj.key === 'employee'));
      newMeta.fields[objIndex] = {
        key: 'job',
        label: 'Jabatan',
        required: true,
        message: 'Jabatan harus diisi',
        widget: 'select',
        options: jobList,
        widgetProps: {
          mode: 'multiple'
        },
      }
    }
  }
  if (form.getFieldValue('approval_1')) {
    if (form.getFieldValue('approval_1') === 'jabatan') {
      newMeta.fields[newMeta.fields.length + 1] = ({
        key: 'job_approval1',
        label: 'Nama Jabatan',
        widget: 'select',
        required: true,
        message: 'Nama Jabatan harus diisi',
        options: jobList,
        widgetProps: {
          showSearch: true
        },
      })
      newMeta.fields[newMeta.fields.length + 2] = (
        {
          key: 'approval_2',
          label: 'Persetujuan 2',
          widget: 'select',
          required: true,
          message: 'Persetujuan 2 harus diisi',
          options: [
            { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
            { value: 'atasan_langsung', label: 'Atasan Langsung' },
            { value: 'jabatan', label: 'Jabatan' },
          ],
        }
      )
    }
    if (form.getFieldValue('approval_1') === 'atasan_langsung') {
      newMeta.fields[newMeta.fields.length + 1] = (
        {
          key: 'approval_2',
          label: 'Persetujuan 2',
          required: true,
          message: 'Persetujuan 2 harus diisi',
          widget: 'select',
          options: [
            { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
            { value: 'atasan_langsung', label: 'Atasan Langsung' },
            { value: 'jabatan', label: 'Jabatan' },
          ],
        }
      )
    }
  }
  if (form.getFieldValue('approval_2')) {
    if (form.getFieldValue('approval_2') === 'jabatan' && form.getFieldValue('approval_1') !== 'tanpa_persetujuan') {
      newMeta.fields[newMeta.fields.length + 1] = ({
        key: 'job_approval2',
        label: 'Nama Jabatan',
        widget: 'select',
        required: true,
        options: jobList,
        widgetProps: {
          showSearch: true
        },
      })
    }
  }

  return (
    <div
      className="add-claim-configuration-page"
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Form form={form} layout="horizontal" onFinish={onFinish} onValuesChange={forceUpdate}>
        <div>
          <div className="form-wrapper">
            <div>
              <Breadcrumb breadcrumbConf={breadcrumbConf} />
              <Form.Item
                className="form-footer"
                style={{ width: '100%', marginTop: 20 }}
              >
                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  disabled={isLoading}
                >
                  Simpan
                </Button>
                &nbsp; &nbsp;
                <Button
                  onClick={() => history.push('/app/hr-claim/configuration/list-configuration')}
                >
                  Batal
                </Button>
              </Form.Item>
              <div style={{ border: '1px solid #000', padding: 10, overflowY: 'scroll', height: '70vh' }}>
                <FormBuilder form={form} meta={meta} />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
