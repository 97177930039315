import { useEffect, useState } from 'react';
import EmployeeAttendanceDetailTemplate from '../../../templates/employee-attendance-detail-template/EmployeeAttendanceDetailTemplate';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import moment from 'moment';
import { AttendanceDetailConstant } from '../../../../../../config/AttendanceDetail.constant';
import { ToolbarAttendanceDetailActionButton } from '../../../templates/employee-attendance-detail-template/components/toolbar-attendance-detail-template/ToolbarAttendanceDetailTemplate.interface';
import HrEmployeeAttendanceDetailUseCaseImpl from './HrEmployeeAttendanceDetail.usecase.impl';
import { AttendanceConstant } from '../../../../../../config/Attendance.constant';

const useCaseImpl = new HrEmployeeAttendanceDetailUseCaseImpl();

let attendanceId = '';

export default function HrEmployeeAttendanceDetail({ match }) {
  const history = useHistory();
  attendanceId = match.params.attendanceId;
  let [isEditMode, setIsEditMode] = useState(false);
  let [listDetail, setListDetail] = useState([]);
  let [checkinLocation, setCheckinLocation] = useState<any>();
  let [checkoutLocation, setCheckoutLocation] = useState<any>();
  const [geoFencing, setGeoFencing] = useState<any>(null);
  const attendanceDetail: any = (AttendanceConstant.DEFAULT_EMPTY_DATA_SOURCE[0]);

  let [employee, setEmployee] = useState({
    active: true,
    value: '',
    editMode: isEditMode,
    options: [],
    rules: [{ required: true, message: 'Mohon lengkapi kolom ini' }],
  });
  let [absentStatus, setAbsentStatus] = useState({ active: true, value: true, editMode: true });
  let [checkinDate, setCheckinDate] = useState({
    active: true,
    value: '',
    editMode: isEditMode,
    rules: [{ required: true, message: 'Mohon lengkapi kolom ini' }],
  });
  let [checkoutDate, setCheckoutDate] = useState({
    active: true,
    value: '',
    editMode: isEditMode,
    rules: [{ required: true, message: 'Mohon lengkapi kolom ini' }],
  });
  let [statusCheckout, setStatusCheckout] = useState({ active: true, value: null, editMode: false });
  let [workedHours, setWorkedHour] = useState({ active: true, value: '', editMode: false });
  let [reason, setReason] = useState({
    active: true,
    value: '',
    editMode: isEditMode,
  });
  const [initialData, setInitialData] = useState<any>({});
  let [isLoading, setIsLoading] = useState(true);
  let [visibleWarningModal, setVisibleWarningModal] = useState(false);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Absensi Seluruh Karyawan',
        href: "/v2/app/hr-attendance/employee-attendance",
      },
      {
        clickable: false,
        displayPathName: `${initialData.name} / ${initialData.absent_date}`,
      }
    ],
  };

  const addButton: ToolbarAttendanceDetailActionButton = {
    label: 'UBAH',
    type: 'primary',
    active: true,
    onClick: onClickUpdate,
    disabled: isLoading,
  };
  const deleteButton: ToolbarAttendanceDetailActionButton = {
    label: 'HAPUS',
    type: 'ghost',
    active: true,
    onClick: onClickDelete,
    disabled: isLoading,
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    const attendanceDetail: any = await useCaseImpl.getAttendance(attendanceId);
    let tempInitial = {
      employee: attendanceDetail.employee_id,
      is_absent: attendanceDetail.status_absent,
      checkin_date: attendanceDetail.check_in ? moment(attendanceDetail.check_in) : null,
      checkout_date: attendanceDetail.check_out ? moment(attendanceDetail.check_out) : null,
      reason: attendanceDetail.reason,
      is_checkout: !!attendanceDetail.check_out,
      name: attendanceDetail.nama,
      absent_date: attendanceDetail.status_absent_date,
      duration: attendanceDetail.worked_hours,
      checkinRadius: attendanceDetail.check_in_radius,
      checkoutRadius: attendanceDetail.check_out_radius
    }
    const params = {
      emp_id: attendanceDetail.employee_id,
    };
    const getGeofencing = await useCaseImpl.getGeofencing(params);
    if (!!getGeofencing) {
      if (!!getGeofencing) {
        setGeoFencing(getGeofencing);
      }
    }
    setInitialData(tempInitial)
    
    if(!!attendanceDetail) {

      setEmployee((prevState) => ({
        ...prevState,
        value: attendanceDetail.nik + attendanceDetail.nama,
      }));
      setAbsentStatus((prevState) => ({
        ...prevState,
        value: attendanceDetail?.status_absent,
      }));
      setCheckinDate((prevState) => ({
        ...prevState,
        active: !!attendanceDetail?.check_in,
        value: attendanceDetail?.check_in ? moment(attendanceDetail?.check_in).format('YYYY-MM-DD HH:mm:ss') : null,
      }));
      setStatusCheckout((prevState) => ({
        ...prevState,
        value: !!attendanceDetail.check_out,
      }));
      setCheckoutDate((prevState) => ({
        ...prevState,
        value: attendanceDetail?.check_out ? moment(attendanceDetail?.check_out).format('YYYY-MM-DD HH:mm:ss') : '' ,
      }));
      setWorkedHour((prevState) => ({
        ...prevState,
        active: !!attendanceDetail.worked_hours,
        value: attendanceDetail.worked_hours,
      }));
      setReason((prevState) => ({
        ...prevState,
        value: attendanceDetail.reason,
      }));
      setListDetail(attendanceDetail.list_detail)
      const loc = JSON.parse(attendanceDetail?.gmap_loc);
      setCheckinLocation({ lat: loc?.position?.lat, lng: loc?.position?.lng })
      setCheckoutLocation({ lat: loc?.position_out?.lat, lng: loc?.position_out?.lng })
    }

    setIsLoading(false);
  }

  async function onClickUpdate() {
    history.push(`/app/hr-attendance/employee-attendance/update/${attendanceId}`);
  }

  function onClickDelete() {
    setVisibleWarningModal(true);
  }

  function deleteToEndPoint() {
    setIsLoading(true);
    const params = {
      id: [attendanceId],
    };

    useCaseImpl.deleteAttendance(params).then(
      (res: any) => {
        setIsLoading(false);
        const data = res.data;

        if (data.status === 200) {
          history.push('/app/hr-attendance/employee-attendance');
        }
      },
      (err) => {
        setIsLoading(false);
        setVisibleWarningModal(false);
      },
    );
  }

  return (
    <div
      className="employee-submission-leave-detail"
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Modal
        title="Hapus Data"
        cancelText="BATALKAN"
        visible={visibleWarningModal}
        cancelButtonProps={{ type: 'ghost' }}
        onOk={deleteToEndPoint}
        onCancel={() => setVisibleWarningModal(false)}
      >
        <p>Apakah yakin ingin menghapus data ini?</p>
      </Modal>
      
      <EmployeeAttendanceDetailTemplate
        breadcrumbConf={breadcrumbConf}
        submitButton={{ active: false }}
        firstActionButton={addButton}
        secondActionButton={deleteButton}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        employee={employee}
        absentStatus={absentStatus}
        reason={reason}
        checkin={checkinDate}
        checkout={checkoutDate}
        statusCheckout={statusCheckout}
        workedHours={workedHours}
        listDetail={listDetail}
        checkinLocation= {checkinLocation}
        checkoutLocation= {checkoutLocation}
        isLoading={isLoading}
        isEditMode={isEditMode}
        geoFencing={geoFencing}
        checkinRadius={initialData.checkinRadius}
        checkoutRadius={initialData.checkoutRadius}
      />
    </div>
  );
}