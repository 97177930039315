import { Button, Card, Row } from 'antd';
import thankyouImage from './assets/thankyou.png';

export default function ThankYouLetter() {
  const backToHome = () => {
    window.location.href = "/"
  }

  return (
    <Card className="thank-you-letter">
      <img src={thankyouImage} alt="" />

      <h1>TERIMA KASIH</h1>

      <Row align="middle" justify='center' style={{ fontSize: '16px', textAlign: 'center' }}>
        <span>Silahkan periksa kotak masuk email Anda (periksa kotak spam jika email tidak tidak ditemukan) dan klik <b>link verifikasi</b> untuk menyelesaikan proses pendaftaran</span>
      </Row>

      <Button className='back-button' onClick={backToHome} type='primary'>Kembali ke beranda</Button>
    </Card>
  );
}
