import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';

export default class ListApprovalLoanUseCase {
  convertOutputSortToEndPointFormat(tableOutputSort) {
    let result = '';
    const columnKey = tableOutputSort.columnKey;
    const order = tableOutputSort.order;

    if (order) {
      switch (columnKey) {
        default:
          break;
        case 'create_date':
          result += 'create_date|';
          break;
        case 'write_date':
          result += 'write_date|';
          break;
      }

      switch (order) {
        default:
          break;
        case 'ascend':
          result += 'asc';
          break;
        case 'descend':
          result += 'desc';
          break;
      }
    }

    return result;
  }

  getAll(params) {
    const URL = Constant.URL_BASE_LOAN + '/v3/request/approval/loan/pending';
    return API.get(URL, { params });
  }

  export(params) {
    const URL = Constant.URL_BASE_LOAN + '/v3/request/approval/loan/export';
    return API.get(URL, { params });
  }

  update(body) {
    const URL = Constant.URL_BASE_LOAN + '/v3/request/approval/loan/update';
    return API.put(URL, body);
  }
}
