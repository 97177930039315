import { Checkbox, Col, Row } from 'antd';

export default function CheckboxGroup({ onChange, disabled }) {
  return (
    <Checkbox.Group style={{ width: '100%', marginTop: '15px' }} onChange={onChange}>
      <Col>
        <Row style={{ marginBottom: '10px' }}>
          <Checkbox disabled={disabled} value="A">
            Dengan ini saya/kami menyatakan telah memiliki rekening di Bank OCBC dan data yang
            saya/kami berikan di atas adalah lengkap, benar, dan terkini
          </Checkbox>
        </Row>
        <Row>
          <Checkbox disabled={disabled} value="B">
            <p>
              Dengan ini saya/kami menyatakan telah menyetujui{' '}
              <a onClick={null}>syarat dan ketentuan</a> untuk layanan HiRa
            </p>
          </Checkbox>
        </Row>
      </Col>
    </Checkbox.Group>
  );
}
