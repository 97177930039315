import { useHistory } from 'react-router-dom';
import CardViewListTemplate from '../../../../../templates/card-view-list-template/CardViewListTemplate';
import { useEffect, useState } from 'react';
import { CardViewListTemplatePagination } from '../../../../../templates/card-view-list-template/CardViewListTemplate.interface';
import ToastService from '../../../../../services/Toast.service';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import ListMySubmissionClaimUseCase from './ListSubmissionLoan.usecase';
import { ListSubmissionLoanConstant } from './ListSubmissionLoan.constant';
import { getSubmissionLoanList } from '../../../../../@core/mapper/loan/GetSubmissionLoan.mapper';

const usecase = new ListMySubmissionClaimUseCase();
const toastService = new ToastService();
const localNotificationService = new LocalNotificationService();
const constant = ListSubmissionLoanConstant;

let page = 1;
let limit = 10;
let sort = 'write_date|desc';
let name;
let date_request;
let config_type;
let loadPagination = true;
let selectionId = [];

export default function ListSubmissionLoan() {
  const history = useHistory();

  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 600, x: 'max-content' });
  let [visibleDeleteButton, setVisibleDeleteButton] = useState(false);
  let [pagination, setPagination] = useState<CardViewListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });

  let tableHeader: any = constant.TABLE_HEADER;
  let searchColumnKey = constant.SEARCH_COLUMN_KEY;
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Pengajuan Pinjaman',
        href: '/v2/app/hr-loan/submission-loan',
      },
    ],
  };

  useEffect(() => {
    loadList();
  }, []);

  function loadList() {
    setIsLoading(true);
    const params = {
      page,
      limit,
      sort,
      name,
      date_request,
      config_type,
      with_meta: true,
    };

    usecase.getAll(params).then(
      (res: any) => {
        setIsLoading(false);
        const content = res.data.content;
        const metaData = res.data.metadata;
        const href = "/v2/app/hr-loan/submission-loan/"

        const resultDataSource = content?.length
          ? getSubmissionLoanList(content, href)
          : constant.DEFAULT_EMPTY_DATA_SOURCE;

        setDataSource(resultDataSource);
        updatePaginationState(metaData?.total_records);

        loadPagination = true;
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      {
        key: 'export',
        label: 'Ekspor',
        onClick: () => {
          setIsLoading(true);
          const params = {
            ids: selectionId,
            sort,
            name,
            date_request,
            config_type,
            with_meta: true,
          };

          usecase.export(params).then((resp: any) => {
            setIsLoading(false);
            const content = resp.data.content;
            if (resp.status === 200) {
              const data = content.data;
              let sampleArr = base64ToArrayBuffer(data);
              saveByteArray(content.filename, sampleArr);
            }
          }, (err) => {
            setIsLoading(false)
          });
        },
      },
      {
        key: 'cancel',
        label: 'Batalkan',
        disabled: !visibleDeleteButton,
        onClick: () => {
          cancel(selectionId);
        },
      },
    ],
  };

  function cancel(ids) {
    setIsLoading(true);

    const params = { ids };

    usecase.cancel(params).then(
      (res: any) => {
        const data = res.data;
        const message = data.content;
        localNotificationService.openSuccess(message, '', 1);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      (err) => {
        setIsLoading(false);
        toastService.error(err);
      },
    );
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSort(outputSort) {
    if (outputSort && usecase.convertOutputSortToEndPointFormat(outputSort) !== sort) {
      page = 1;
      loadPagination = false;

      sort = usecase.convertOutputSortToEndPointFormat(outputSort);
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;

    if (outputSearchColumn) {
      if (outputSearchColumn.submission_number !== undefined && outputSearchColumn.submission_number !== name) {
        name = outputSearchColumn.submission_number;
        isLoadList = true;
      }

      if (
        outputSearchColumn.submission_date !== undefined &&
        outputSearchColumn.submission_date !== date_request
      ) {
        date_request = outputSearchColumn.submission_date;
        isLoadList = true;
      }

      if (outputSearchColumn.loan_type !== undefined && outputSearchColumn.loan_type !== config_type) {
        config_type = outputSearchColumn.loan_type;
        isLoadList = true;
      }
    }

    if (isLoadList) {
      page = 1;
      loadPagination = false;
      loadList();
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionId) {
      selectionId = outputSelection;
      setVisibleDeleteButton(!!selectionId.length)
    }
  }

  const addButton = {
    onClick: () => {
      history.push('/app/hr-loan/submission-loan/new/');
    },
    disabled: isLoading,
  };

  function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte) {
    let blob = new Blob([byte], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    let fileName = reportName;
    link.download = fileName;
    link.click();
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;

    processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  const onRow = (e) => {
    
  };

  return (
    <div className="list-submission-loan">
      <CardViewListTemplate
        breadcrumbConf={breadcrumbConf}
        addButton={addButton}
        deleteButton={{ visible: false }}
        exportButton={{ visible: false }}
        dropdownButton={dropdownButton}
        tableData={tableData}
        isOnlyTable={true}
        EditButtonCard={{ visible: false, disabled: false, onClick: null }}
        DeleteButtonCard={{ visible: false, disabled: false, onClick: null }}
        selectedRowKeys={selectionId}
        tableHeader={tableHeader}
        tableOnChange={tableOnChange}
        onRow={onRow}
        tablePagination={pagination}
        tableScroll={tableScroll}
        isLoading={isLoading}
      />
    </div>
  );
}
