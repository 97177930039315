import axios from 'axios';
import Constant from '../config/Constants';
import CookiesService from './Cookies.service';
import ToastService from './Toast.service';

const cookiesService = new CookiesService();
const toastService = new ToastService();

const API = axios.create({
  baseURL: Constant.URL_BASE_PATH,
  responseType: 'json',
});

const RespondError = (resp) => {
  // let status = resp.status === null ? 1001 : resp.status;
  let status = !resp || resp.status === null ? 1001 : resp.status;
  let out = '';
  out = '[' + status + '] Something went wrong.';

  if (resp) {
    if ('data' in resp && resp.data !== null) {
      if (
        'error_description' in resp.data &&
        resp.data.error_description !== null &&
        resp.data.error_description !== ''
      ) {
        out = resp.data.error_description;
      } else if ('error' in resp.data && resp.data.error !== null && resp.data.error !== '') {
        out = resp.data.error.message;
      } else if ('detail' in resp.data && resp.data.detail !== null) {
        out = resp.data.detail;
      } else {
        out = resp.data;
      }
    }
  }
  return out;
};

// Response interceptor for API calls
API.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    console.error('error intercept: ', error);
    const originalRequest = error.config;

    if (error.response && error.response.status === 403) {
      return error.response
    } else {
      toastService.error(RespondError(error.response));
    }

    console.error(error.response);
    if (JSON.stringify(error) === '{}') {
      cookiesService.remove();
      window.location.href = '/';
    } else if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      console.error('error interceptor: ', error);
      cookiesService.remove();
      window.location.href = '/v2/login';
    } else if (error.response && error.response.status === 403) {
      // originalRequest._retry = true;
      // window.location.href = '/v2/app/no-access';
    }
    return Promise.reject(error.response);
  },
);

// Request interceptor for API calls
API.interceptors.request.use(
  (request) => {
    if (!request.url.includes('auth') && !request.url.includes('verified-email')) {
      const auth = cookiesService.getJSON();
      const access_token = auth.access_token;
      request.headers['Authorization'] = 'Bearer ' + access_token;
    }
    return request;
  },
  (error) => {
    console.error('error intercept: ', error);
    return Promise.reject(error.response);
  },
);

export default API;
