import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Radio, Space, InputNumber, Select, Modal, Tag, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import FormBuilder from 'antd-form-builder';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import PageLoader from '../../../../../components/loader/PageLoader';
import { useHistory } from 'react-router-dom';
import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service"
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import UpdateLoanConfig from './UpdateLoanConfiguration.usecase';

const useCaseImpl = new UpdateLoanConfig();
const localNotificationService = new LocalNotificationService();
let loanId = '';
export default function HrUpdateConfigurationLoan({ match }) {
  const history = useHistory();
  loanId = match.params.loanId;
  const [form] = Form.useForm();
  const [, updateState] = React.useState<any>(); const forceUpdate = React.useCallback(() => updateState({}), []);
  const [employeeList, setEmployeeList] = useState([]);
  const [jobListOption, setJobListOption] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [initialData, setInitialData] = useState<any>({});
  const [showModalJob, setShowModalJob] = useState<any>(false);
  const [showModalEmployee, setShowModalEmployee] = useState<any>(false);
  const [modalLoading, setModalLoading] = useState(true);
  const [monthJoin, setMonthJoin] = useState<any>();
  const [loanRel, setLoanRel] = useState([]);
  const [totalRel, setTotalRel] = useState<any>(0);
  const [page, setPage] = useState(1);

  const [breadcrumbConf, setBreadcrumbConf] = useState<any>({
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Konfigurasi Pinjaman',
        href: '/v2/app/hr-loan/configuration/list-configuration',
      },
      { clickable: false, displayPathName: '' },
    ],
  });


  const ActiveWhen = ({ value, onChange }) => {
    return (
      <Radio.Group onChange={onChange} value={value}>
        <Space direction="vertical">
          <Radio value={'mulai_bergabung'}>Mulai Bergabung</Radio>
          <Radio
            value={'bergabung_dibulan'}>Bergabung di Bulan ke
            {value === 'bergabung_dibulan' ? <InputNumber defaultValue={monthJoin} onChange={(e) => setMonthJoin(e)} style={{ width: 50, marginLeft: 10 }} /> : null}
          </Radio>
        </Space>
      </Radio.Group>
    )
  }

  const JOB_TABLE_HEADER = [
    {
      title: 'Jabatan',
      dataIndex: 'name',
    },
    {
      title: 'Departement',
      dataIndex: 'dept_name',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (value, result) => {
        return (
          <DeleteOutlined onClick={(e) => {
            setModalLoading(true);
            e.preventDefault();
            const param: any = {
              ids: [result.id],
              config_type: "job"
            }
            const URL = Constant.URL_BASE_LOAN + `/v3/configuration/loan/${loanId}/rel`
            return API.delete(URL, { data: param }).then((res: any) => {
              initialPage();
            })
          }} />
        )
      }
    },
  ]

  const EMPLOYEE_TABLE_HEADER = [
    {
      title: 'NIK',
      dataIndex: 'nik',
    },
    {
      title: 'Nama',
      dataIndex: 'name',
    },
    {
      title: 'Jabatan',
      dataIndex: 'job_name',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (value, result) => {
        return (
          <DeleteOutlined onClick={(e) => {
            setModalLoading(true);
            e.preventDefault();
            const param: any = {
              ids: [result.id],
              config_type: "employee"
            }
            const URL = Constant.URL_BASE_LOAN + `/v3/configuration/loan/${loanId}/rel`
            return API.delete(URL, { data: param }).then((res: any) => {
              initialPage();
            })
          }} />
        )
      }
    },
  ]

  const meta: any = {
    columns: 1,
    fields: [
      {
        key: 'name',
        label: 'Nama Pinjaman',
        rules: [{ required: true, whitespace: true, message: "Nama Pinjaman harus diisi", }],
      },
      {
        key: 'max_plafon',
        label: 'Maksimum Plafon',
        required: true,
        message: 'Maksimum Plafon harus diisi',
        widget: 'number',
        widgetProps: {
          style:{ width: '50%' },
          formatter: value => `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ','),
        },
      },
      {
        key: 'interest_type',
        label: 'Metode Bunga',
        widget: 'select',
        required: true,
        message: 'Metode Bunga harus diisi',
        options: [
          { value: 'tanpa_bunga', label: 'Tanpa Bunga' },
          { value: 'flat', label: 'Flat' },
          { value: 'anuitas', label: 'Anuitas' },
        ],
      },
      {
        key: 'interest',
        label: '% Bunga',
        required: true,
        widget: 'number',
        message: 'Bunga harus diisi',
        widgetProps: {
          min: 1
        }
      },
      {
        key: 'max_tenor',
        label: 'Maksimum Tenor(Bulan)',
        required: true,
        widget: 'number',
        message: 'Maksimum Tenor harus diisi',
        widgetProps: {
          min: 1
        }
      },
      {
        key: 'active_when',
        label: 'Aktif Ketika',
        forwardRef: true,
        widget: ActiveWhen,
        options: [
          { value: 'mulai_bergabung', label: 'Mulai Bergabung' },
          { value: 'bergabung_dibulan', label: 'Bergabung di Bulan ke' },
        ],
        initialValue: 'mulai_bergabung',
      },
      {
        key: 'config_type',
        label: 'Berlaku Untuk',
        widget: 'radio-group',
        disabled: true,
        forwardRef: true,
        options: [
          { value: 'job', label: 'Jabatan' },
          { value: 'employee', label: 'Karyawan' },
        ],
      },
      {
        key: 'current_job',
        label: 'Jabatan yang sudah dipilih',
        readOnly: true,
        viewWidget: (job) => {
          const list_job = job.value && job.value.map(item => JSON.parse(item)).map(function (row) {
            return {
              job_id: row.id,
              name: row.job_name,
              dept_name: row.dept_name,
              dept_id: row.dept_id

            }
          })
          return (
            <Space direction='vertical' style={{ width: '70%' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {
                  list_job && list_job.map((item, i) => {
                    return (
                      <div style={{ marginBottom: 10 }} key={i}>
                        <Tag color='red'>
                          <span key={item.id}>
                            {item.name}
                          </span>
                        </Tag>
                      </div>
                    );
                  })
                }
              </div>
              <div>
                <Button type="link" onClick={() => setShowModalJob(true)}>Tampilkan Semua</Button>
              </div>
            </Space>
          )
        }
      },
      {
        key: 'job',
        label: 'Jabatan',
        message: 'Jabatan harus diisi',
        widget: 'select',
        options: jobListOption,
        widgetProps: {
          mode: 'multiple'
        },
      },
      {
        key: 'approval_1',
        label: 'Persetujuan 1',
        widget: 'select',
        options: [
          { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
          { value: 'atasan_langsung', label: 'Atasan Langsung' },
          { value: 'jabatan', label: 'Jabatan' },
        ],
      },
    ],
  };

  useEffect(() => {
    initialPage();
  }, [])

  useEffect(() => {
    getLoanRel()
  }, [initialData]);

  async function getLoanRel(current = 1) {
    setPage(current);
    let params = {
      loanId: loanId,
      page: current,
      limit: 10,
      config_type: initialData?.config_type,
      with_meta: true
    }
    const tempRel = await useCaseImpl.getLoanRel(params)
    
    if(!!tempRel) {
      setLoanRel(tempRel.content);
      setTotalRel(tempRel.metadata.total_records)
    }
    setModalLoading(false);
  }

  async function initialPage() {
    setIsLoading(true);
    const getJobOption = await useCaseImpl.getJob();
    const employeeList = await useCaseImpl.getEmpl();
    setJobListOption(getJobOption);
    setEmployeeList(employeeList);

    const loanDetail: any = await useCaseImpl.getLoanConfigurationDetail(loanId);
    let inital_job: any = [];
    let inital_employee = [];
    if (loanDetail.view_list_job) {
      inital_job =
        getJobOption.reduce((acc, val, i) => {
          if (loanDetail.view_list_job.map(item => item.job_id).includes(val.key)) {
            acc.push(val.value);
          }

          return acc;
        }, []);
    }
    if (loanDetail.view_list_emp) {
      inital_employee =
        employeeList.reduce((acc, val, i) => {
          if (loanDetail.view_list_emp.map(item => item.employee_id).includes(val.key)) {
            acc.push(val.value);
          }
          return acc;
        }, []);
    }
    
    let initialJobApproval_1;
    let initialJobApproval_2;
    if (loanDetail.approval_1) {
      initialJobApproval_1 = await useCaseImpl.getJob({ job_id: loanDetail.job_approval_1 });
    }
    if (loanDetail.approval_2) {
      initialJobApproval_2 = await useCaseImpl.getJob({ job_id: [loanDetail.job_approval_2] });
    }

    let tempInitial = {
      name: loanDetail.name,
      max_plafon: loanDetail.loan_plafon,
      interest_type: loanDetail.loan_rule,
      interest: loanDetail.loan_interest,
      max_tenor: loanDetail.max_installment,
      active_when: loanDetail.active_when,
      month_join: loanDetail.month_join,
      config_type: loanDetail.config_type,
      approval_1: loanDetail.approval_1,
      approval_2: loanDetail.approval_2,
      job_approval1: initialJobApproval_1 && initialJobApproval_1[0]?.value,
      job_approval2: initialJobApproval_2 && initialJobApproval_2[0]?.value,
      view_list_emp: loanDetail.view_list_emp,
      view_list_job: loanDetail.view_list_job,
      current_job: inital_job,
      current_employee: inital_employee,
    }

    const tempBreadcrumbConf = JSON.parse(JSON.stringify(breadcrumbConf));
    tempBreadcrumbConf.manualData[1].displayPathName = loanDetail?.name;
    setBreadcrumbConf(tempBreadcrumbConf);
    setMonthJoin(loanDetail.month_join);
    setInitialData(tempInitial)
    setIsLoading(false);
    setModalLoading(false);
  }

  async function onFinish(e) {
    setIsLoading(true);
    const list_job = e.job && e.job.map(item => JSON.parse(item)).map(function (row) {
      return {
        job_id: row.id,
        name: row.job_name,
        dept_name: row.dept_name,
        dept_id: row.dept_id

      }
    })
    const list_employee = e.employee && e.employee.map(item => JSON.parse(item)).map(function (row) {
      return {
        employee_id: row.id,
        nik: row.nik,
        name: row.name,
        alternative_name: row.alternative_name,
        job_id: row.job_id,
        job_name: row.job_name,
        dept_id: row.dept_id,
        dept_name: row.dept_name
      }
    })
    let tempParam: any = {}
    e.employee && (tempParam['list_emp'] = list_employee)
    e.job && (tempParam['list_job'] = list_job)
    e.job_approval1 && (tempParam['job_approval_1'] = JSON.parse(e.job_approval1).id)
    e.job_approval2 && (tempParam['job_approval_2'] = JSON.parse(e.job_approval2).id)

    const params = {
      id: loanId,
      name: e.name.trim(),
      loan_rule: e.interest_type,
      loan_plafon: e?.max_plafon?.toString(),
      loan_interest: e?.interest?.toString(),
      max_installment: e?.max_tenor?.toString(),
      config_type: e.config_type,
      active_when: e.active_when,
      month_join: monthJoin ? monthJoin.toString() : '0',
      approval_1: e.approval_1,
      approval_2: e.approval_2,
      view_list_emp: initialData.view_list_emp,
      view_list_job: initialData.view_list_job,
      is_2nd_appr: (e.approval_2 && e.approval_2 !== 'tanpa_persetujuan') ? true : false,
      ...tempParam,
    }

    useCaseImpl.submitLoanConfiguration(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          history.push('/app/hr-loan/configuration/list-configuration');
          localNotificationService.openSuccess('Konfigurasi Pinjaman Berhasil Diubah', '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }
  let newMeta = meta;
  if (form.getFieldValue('interest_type')) {
    if (form.getFieldValue('interest_type') === 'tanpa_bunga') {
      const objIndex = newMeta.fields.findIndex((obj => obj.key === 'interest'));
      newMeta.fields[objIndex] = {
        key: 'hide_plafon_type',
        render: () => {
          return null
        }
      }
    }
  } else {
    if (initialData?.interest_type === 'tanpa_bunga') {
      const objIndex = newMeta.fields.findIndex((obj => obj.key === 'interest'));
      newMeta.fields[objIndex] = {
        key: 'hide_plafon_type',
        render: () => {
          return null
        }
      }
    }
  }
  if (form.getFieldValue('config_type')) {
    if (form.getFieldValue('config_type') === 'employee') {
      const objIndex = newMeta.fields.findIndex((obj => obj.key === 'job'));
      const objIndexCurrent = newMeta.fields.findIndex((obj => obj.key === 'current_job'));
      newMeta.fields[objIndex] = {
        key: 'employee',
        label: 'Karyawan',
        message: 'Karyawan harus diisi',
        widget: 'select',
        options: employeeList,
        widgetProps: {
          mode: 'multiple'
        },
      }
      newMeta.fields[objIndexCurrent] = {
        key: 'current_employee',
        label: 'Karyawan yang sudah dipilih',
        readOnly: true,
        viewWidget: (employee) => {
          const list_employee = employee.value && employee.value.map(item => JSON.parse(item)).map(function (row) {
            return {
              key: row.id,
              id: row.id,
              name: row.name,
              alternative_name: row.alternative_name,

            }
          })
          return (
            <Space direction='vertical' style={{ width: '70%' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {
                  list_employee && list_employee.map((item, i) => {
                    return (
                      <div style={{ marginBottom: 10 }} key={i}>
                        <Tag color='red'>
                          <span key={item.id}>
                            {item.name}
                          </span>
                        </Tag>
                      </div>
                    );
                  })
                }
              </div>
              <div>
                <Button type="link" onClick={() => setShowModalEmployee(true)}>Tampilkan Semua</Button>
              </div>
            </Space>
          )
        }
      }
    } else if (form.getFieldValue('config_type') === 'job') {
      const objIndex = newMeta.fields.findIndex((obj => obj.key === 'employee'));
      newMeta.fields[objIndex] = {
        key: 'job',
        label: 'Jabatan',
        required: true,
        message: 'Jabatan harus diisi',
        widget: 'select',
        options: jobListOption,
        widgetProps: {
          mode: 'multiple'
        },
      }
    }
  } else {
    if (initialData?.config_type === 'employee') {
      const objIndex = newMeta.fields.findIndex((obj => obj.key === 'job'));
      const objIndexCurrent = newMeta.fields.findIndex((obj => obj.key === 'current_job'));
      newMeta.fields[objIndex] = {
        key: 'employee',
        label: 'Karyawan',
        message: 'Karyawan harus diisi',
        widget: 'select',
        options: employeeList,
        widgetProps: {
          mode: 'multiple'
        },
      }

      newMeta.fields[objIndexCurrent] = {
        key: 'current_employee',
        label: 'Karyawan yang sudah dipilih',
        readOnly: true,
        viewWidget: (employee) => {
          const list_employee = employee.value && employee.value.map(item => JSON.parse(item)).map(function (row) {
            return {
              key: row.id,
              id: row.id,
              name: row.name,
              alternative_name: row.alternative_name,
            }
          })
          return (
            <Space direction='vertical' style={{ width: '70%' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {
                  list_employee && list_employee.map((item, i) => {
                    return (
                      <div style={{ marginBottom: 10 }} key={i}>
                        <Tag color='red'>
                          <span key={item.id}>
                            {item.name}
                          </span>
                        </Tag>
                      </div>
                    );
                  })
                }
              </div>
              <div>
                <Button type="link" onClick={() => setShowModalEmployee(true)}>Tampilkan Semua</Button>
              </div>
            </Space>
          )
        }
      }
    }
  }
  if (form.getFieldValue('approval_1')) {
    if (form.getFieldValue('approval_1') === 'jabatan') {
      newMeta.fields[newMeta.fields.length + 1] = ({
        key: 'job_approval1',
        label: 'Nama Jabatan',
        widget: 'select',
        required: true,
        options: jobListOption,
        widgetProps: {
          showSearch: true
        },
      })
      newMeta.fields[newMeta.fields.length + 2] = (
        {
          key: 'approval_2',
          label: 'Persetujuan 2',
          required: true,
          widget: 'select',
          options: [
            { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
            { value: 'atasan_langsung', label: 'Atasan Langsung' },
            { value: 'jabatan', label: 'Jabatan' },
          ],
        }
      )
    }
    if (form.getFieldValue('approval_1') === 'atasan_langsung') {
      newMeta.fields[newMeta.fields.length + 1] = (
        {
          key: 'approval_2',
          label: 'Persetujuan 2',
          required: true,
          widget: 'select',
          options: [
            { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
            { value: 'atasan_langsung', label: 'Atasan Langsung' },
            { value: 'jabatan', label: 'Jabatan' },
          ],
        }
      )
    }
  } else {
    if (initialData?.approval_1 === 'jabatan') {
      newMeta.fields[newMeta.fields.length + 1] = ({
        key: 'job_approval1',
        label: 'Nama Jabatan',
        widget: 'select',
        required: true,
        options: jobListOption,
        widgetProps: {
          showSearch: true
        },
      })
      newMeta.fields[newMeta.fields.length + 2] = (
        {
          key: 'approval_2',
          label: 'Persetujuan 2',
          required: true,
          widget: 'select',
          options: [
            { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
            { value: 'atasan_langsung', label: 'Atasan Langsung' },
            { value: 'jabatan', label: 'Jabatan' },
          ],
        }
      )
    }
    if (initialData?.approval_1 === 'atasan_langsung') {
      newMeta.fields[newMeta.fields.length + 1] = (
        {
          key: 'approval_2',
          label: 'Persetujuan 2',
          required: true,
          widget: 'select',
          options: [
            { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
            { value: 'atasan_langsung', label: 'Atasan Langsung' },
            { value: 'jabatan', label: 'Jabatan' },
          ],
        }
      )
    }
  }
  if (form.getFieldValue('approval_2')) {
    if (
      form.getFieldValue('approval_2') === 'jabatan' &&
      form.getFieldValue('approval_1') !== 'tanpa_persetujuan' &&
      form.getFieldValue('approval_2') !== 'tanpa_persetujuan') {
      newMeta.fields[newMeta.fields.length + 1] = ({
        key: 'job_approval2',
        label: 'Nama Jabatan',
        widget: 'select',
        required: true,
        options: jobListOption,
        widgetProps: {
          showSearch: true
        },
      })
    }
  } else {
    if (initialData?.approval_2 === 'jabatan') {
      newMeta.fields[newMeta.fields.length + 1] = ({
        key: 'job_approval2',
        label: 'Nama Jabatan',
        widget: 'select',
        required: true,
        options: jobListOption,
        widgetProps: {
          showSearch: true
        },
      })
    }
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div
      className="add-loan-configuration-page"
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Form form={form} initialValues={initialData} layout="horizontal" onFinish={onFinish} onValuesChange={forceUpdate}>
        <div>
          <div className="form-wrapper">
            <div>
              <Breadcrumb breadcrumbConf={breadcrumbConf} />
              <Modal
                visible={showModalEmployee}
                onCancel={() => setShowModalEmployee(false)}
                footer={null}
              >
                <div>
                  <Table
                    loading={modalLoading}
                    columns={EMPLOYEE_TABLE_HEADER}
                    dataSource={loanRel}
                    pagination={{
                      showSizeChanger: false,
                      onChange: (page: number) => getLoanRel(page),
                      current: page,
                      total: totalRel,
                    }}
                  />
                </div>
              </Modal>

              <Modal
                visible={showModalJob}
                onCancel={() => setShowModalJob(false)}
                footer={null}
              >
                <div>
                  <Table
                    loading={modalLoading}
                    columns={JOB_TABLE_HEADER}
                    dataSource={loanRel}
                    pagination={{
                      showSizeChanger: false,
                      onChange: (page: number) => getLoanRel(page),
                      current: page,
                      total: totalRel,
                    }}
                  />
                </div>
              </Modal>
              <Form.Item
                className="form-footer"
                style={{ width: '100%', marginTop: 20 }}
              >
                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  disabled={isLoading}
                >
                  Simpan
                </Button>
                &nbsp; &nbsp;
                <Button
                  onClick={() => history.push('/app/hr-loan/configuration/list-configuration')}
                >
                  Batal
                </Button>
              </Form.Item>
              <div style={{ border: '1px solid #000', padding: 10, overflowY: 'scroll', height: '70vh' }}>
                <FormBuilder disabled={false} form={form} meta={meta} />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

